/* This is a JavaScript code that defines a function `getBrand` that makes an HTTP GET request to an
API endpoint using the Axios library. The function uses environment variables to construct the API
endpoint URL and set the API key header. The function returns the response data from the API. The
code also exports the `getBrand` function as the default export of the module. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'brands';

const getBrand = async () => {
	const brandIdentifier = process.env.REACT_APP_BRAND_IDENTIFIER;
	const response = await axios.get(`${baseUrl}/${brandIdentifier}`);
	return response.data;
};

export default {getBrand};
