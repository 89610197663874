/**
 * It renders a popup that asks the admin to confirm the cancellation or approval of a booking
 * @returns A popup that allows the user to approve or cancel a booking.
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import pendingBookingService from '../services/pendingBookings';
import bookingService from '../services/bookings';
import {FaPencilAlt, FaCheck, FaTimes} from 'react-icons/fa';

const ApproveCancelPopup = ({
	booking,
	reason,
	onApprove,
	onCancel,
	onClose,
	isPending,
	handleReasonChange,
}) => {
	const [purpose, setPurpose] = useState(booking.purpose);
	const [editable, setEditable] = useState(false);
	const [startTime, setStartTime] = useState(booking.startTime);
	const [endTime, setEndTime] = useState(booking.endTime);
	const [editableStartTime, setEditableStartTime] = useState(false);
	const [editableEndTime, setEditableEndTime] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [error, setError] = useState(false);

	// Update purpose of booking in database
	const handlePurposeChange = (event) => {
		pendingBookingService
			.updatePurpose(booking.id, {'purpose': purpose})
			.then(() => {
				setEditable(false);
			})
			.catch((error) => {
				console.log(error);
			});

		// try bookingService for approved bookings
		bookingService
			.updatePurpose(booking.id, {'purpose': purpose})
			.then(() => {
				setEditable(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleEditClick = () => {
		setEditable(true);
	};

	const handleCancelClick = () => {
		setEditable(false);
		setPurpose(booking.purpose);
	};

	// Update timings of booking in database
	const handleTimingChange = () => {
		const [startHour, startMin] = startTime.split(':').map(Number);
		const [endHour, endMin] = endTime.split(':').map(Number);

		// Check if the time is in 30-minute blocks
		if (startMin % 30 !== 0 || endMin % 30 !== 0) {
			setError(true);
			setErrorMsg('Time must be in 30-minute blocks');
			return;
		}
		// Check if the end time is after the start time
		if (endHour < startHour || (endHour === startHour && endMin <= startMin)) {
			setError(true);
			setErrorMsg('Please enter an end time after the start time');
			return;
		}

		// Update the timings in the database
		pendingBookingService
			.updateTimings(booking.id, {'startTime': startTime, 'endTime': endTime})
			.then(() => {
				setEditableStartTime(false);
				setEditableEndTime(false);
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 409) {
					setError(true);
					setErrorMsg('There is a clash with another booking');
				}
			});

		// try bookingService for approved bookings
		bookingService
			.updateTimings(booking.id, {'startTime': startTime, 'endTime': endTime})
			.then(() => {
				setEditableStartTime(false);
				setEditableEndTime(false);
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 409) {
					setError(true);
					setErrorMsg('There is a clash with another booking');
				}
			});

		// Reset error message
		setError(false);
		setErrorMsg('');

		// Update the booking object
		booking.startTime = startTime;
		booking.endTime = endTime;
	};

	const handleEditStartTimeClick = () => {
		setEditableStartTime(true);
	};

	const handleCancelStartTimeClick = () => {
		setEditableStartTime(false);
		setStartTime(booking.startTime);
	};

	const handleEditEndTimeClick = () => {
		setEditableEndTime(true);
	};

	const handleCancelEndTimeClick = () => {
		setEditableEndTime(false);
		setEndTime(booking.endTime);
	};


	// Parse the date string and format it in dd-mm-yyyy format
	const date = new Date(booking.date);
	const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: 'long', year: 'numeric'});

	return (
		<div className="booking-cancel-popup">
			<div className="approve-cancel-popup-content px-5 pb-5" style={{maxWidth: '350px'}}>
				<div style={{textAlign: 'right'}}>
					<button
						className="btn btn-link no-hover close-btn"
						onClick={onClose}
					>
						Close
					</button>
				</div>
				<div className="text-center">
					<h4>
						{formattedDate}
					</h4>
					<h2 className="my-4 text-center">
						<strong>
							<>
								{editable ? (
									<>
										<input
											type="text"
											className="form-control"
											value={purpose}
											onChange={
												(event) => setPurpose(event.target.value)
											}
										/>
										<button className="btn no-hover p-2 m-1" onClick={handlePurposeChange}>
											<FaCheck className='fa-check'/>
										</button>
										<button className="btn no-hover p-2 m-1" onClick={handleCancelClick}>
											<FaTimes className='fa-times'/>
										</button> <br/>
									</>
								) : (
									<>
										{purpose}
										<button className="btn btn-link no-hover ps-2 pt-1" onClick={handleEditClick}>
											<FaPencilAlt className='fa-pencil'/>
										</button>
									</>
								)}
							</>
						</strong>
					</h2>
					<h4>
						<strong>Start: </strong>
					</h4>
					<h4>
						{editableStartTime ? (
							<>
								<input
									type="text"
									className="form-control"
									value={startTime}
									onChange={(event) => setStartTime(event.target.value)}
								/>
								<button className="btn no-hover p-2 m-1" onClick={handleTimingChange}>
									<FaCheck className='fa-check'/>
								</button>
								<button className="btn no-hover p-2 m-1" onClick={handleCancelStartTimeClick}>
									<FaTimes className='fa-times'/>
								</button> <br/>
							</>
						) : (
							<>
								{booking.startTime} <br/>
								<button className="btn btn-link no-hover pt-0 pb-2" onClick={handleEditStartTimeClick}>
									<FaPencilAlt className='fa-pencil'/>
								</button>
							</>
						)}
					</h4>
					<h4>
						<strong>End: </strong>
					</h4>
					<h4>
						{editableEndTime ? (
							<>
								<input
									type="text"
									className="form-control"
									value={endTime}
									onChange={(event) => setEndTime(event.target.value)}
								/>
								<button className="btn no-hover p-2 m-1" onClick={handleTimingChange}>
									<FaCheck className='fa-check'/>
								</button>
								<button className="btn no-hover p-2 m-1" onClick={handleCancelEndTimeClick}>
									<FaTimes className='fa-times'/>
								</button> <br/>
							</>
						) : (
							<>
								{booking.endTime} <br/>
								<button className="btn btn-link no-hover pt-0" onClick={handleEditEndTimeClick}>
									<FaPencilAlt className='fa-pencil'/>
								</button>
							</>
						)}
					</h4>
					{error && (
						<div className="alert alert-danger" role="alert">
							{errorMsg}
						</div>
					)}
				</div>
				<div className="text-align-left my-4">
					<label>
						<strong>Requestor: </strong> {booking.name}
					</label> <br/>
					<label>
						<strong>Department:</strong> {booking.dept}
					</label> <br/>
					<label>
						<strong>Contact:</strong> {booking.user.contactNo}
					</label> <br/>
					<label>
						<strong>Email:</strong> <p style={{overflowX: 'auto', maxWidth: '300px'}}>{booking.user.email}</p>
					</label> <br/>
					<label>
						<strong>Equipment:</strong>
						<ul>
							{booking.equipmentList ? (
								// eslint-disable-next-line
								Object.entries(booking.equipmentList).map(([key, value]) => (
									<li key={key}>{`${key}: ${value}`}</li>
								))
							) : (
								<li>NIL</li>
							)}
						</ul>
					</label> <br/>
					<label>
						<strong>Layout:</strong> {booking.venue.layout?.[booking.layout]?.setup || 'NIL'}
					</label> <br/>
					<label>
						<strong>Custom Layout Request:</strong> {booking.customLayout || 'NIL'}
					</label> <br/>
					<label>
						<strong>Expected Attendees:</strong> {booking.attendees || 'NIL'}
					</label> <br/>
					<label>
						<strong>Remarks:</strong> {booking.remarks || 'NIL'}
					</label> <br/>
					{booking.otp ? (
						<label>
							<strong>OTP:</strong> {booking.otp}
						</label>
					) : (
						null
					)}
				</div>
				<div className="text-center">
					{isPending ?
						(<button className="btn approve-popup-btn" onClick={() => onApprove(booking)}>
							<span className='btn-text'>Approve Request</span>
						</button>
						) : (
							null
						)
					}
					<div>
						<input
							type="text"
							className="form-control my-3 text-center"
							placeholder="Reason for cancellation"
							value={reason}
							style={{height: '40px', margin: 'auto'}}
							onChange={handleReasonChange}
							required
						/>
					</div>
					<button className="btn cancel-popup-btn" onClick={() => onCancel(booking)}>
						<span className='btn-text'>Cancel Booking</span>
					</button>
				</div>
			</div>
		</div>
	);
};

ApproveCancelPopup.propTypes = {
	booking: PropTypes.object.isRequired,
	reason: PropTypes.string.isRequired,
	onApprove: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isPending: PropTypes.bool.isRequired,
	handleReasonChange: PropTypes.func.isRequired,
};


export default ApproveCancelPopup;
