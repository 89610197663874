/**
 * It renders a popup that displays the details of the booking that was just submitted
 * @returns A React component that displays a popup with the details of the booking that was just
 * submitted.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const RequestPopup = ( {booking} ) => {
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;
	const navigate = useNavigate();

	const handleAnotherBooking = () => {
		window.location.reload();
	};

	const isAdmin = localStorage.getItem('admin');

	// Parse the date string and format it in dd-mm-yyyy format
	const date = new Date(booking.date);
	const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});

	return (
		<div className="booking-popup">
			<div className="booking-popup-content">
				<h2 className="mb-3 text-center">{isAdmin ? 'Booking Created' : 'Request Submitted'}</h2>
				<p className="text-center"> {isAdmin ? 'Your booking for' : 'Your request to book'} <strong>{booking.venueName}</strong> has been submitted.</p>
				<div className="text-center">
					<p>
						<strong>Date:</strong> {formattedDate}
					</p>
					<p>
						<strong>Start Time:</strong> {booking.startTime}
					</p>
					<p>
						<strong>End Time:</strong> {booking.endTime}
					</p>
					<p>
						<strong>Purpose:</strong> {booking.purpose}
					</p>
				</div>
				<div className="text-center">
					<p>{isAdmin ? null : 'Please note that the booking will need to be approved.'}</p>
					<p>{isAdmin ? 'You will receive an email an email confirmation.' : 'You will receive an email once your request has been approved.'}</p>
				</div>
				<div className="text-center">
					<button className="btn dark-btn popup-dark-btn btn-sm mb-2" onClick={() => navigate(`/${brandIdentifier}${brandPages.userDashboard}`)}>
						<span className="btn-text">Dashboard</span>
					</button>
					<button className="btn light-btn popup-light-btn btn-sm mb-2" onClick={handleAnotherBooking}>
						<span className="btn-text">Book Again</span>
					</button>
				</div>
			</div>
		</div>
	);
};

RequestPopup.propTypes = {
	booking: PropTypes.shape({
		venueName: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		purpose: PropTypes.string.isRequired,
	}).isRequired,
};

export default RequestPopup;
