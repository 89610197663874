/* This is a module that exports functions for making API requests to a backend server. It uses the
Axios library to make HTTP requests and includes functions for getting all bookings, getting a
specific booking by ID, creating a new booking, updating an existing booking, and deleting a
booking. It also includes a function for retrieving authentication tokens from local storage. The
`baseUrl` variable is used to construct the URL for the API requests. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'users';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const retrieveTokens = () => {
	const admin = window.localStorage.getItem('adminToken');
	const user = window.localStorage.getItem('userToken');

	const adminToken = `Bearer ${admin}`;
	const userToken = `Bearer ${user}`;
	return {adminToken, userToken};
};

const getBookings = (id) => {
	const {userToken} = retrieveTokens();
	const headers = {
		Authorization: userToken,
	};
	const request = axios.get(`${baseUrl}/${id}`, {headers});
	return request.then((response) => response.data);
};

const getAll = () => {
	const {adminToken} = retrieveTokens();
	const headers = {
		Authorization: adminToken,
	};
	const request = axios.get(`${baseBrandUrl}/allUsers`, {headers});
	return request.then((response) => response.data);
};

const create = (bookingObject) => {
	const {adminToken} = retrieveTokens();
	const headers = {
		Authorization: adminToken,
	};
	const request = axios.post(baseBrandUrl, bookingObject, {headers});
	return request.then((response) => response.data);
};

const remove = (id) => {
	const {adminToken} = retrieveTokens();
	const headers = {
		Authorization: adminToken,
	};
	const request = axios.patch(`${baseUrl}/${id}`, null, {headers});
	return request.then((response) => response.data);
};

const update = (id, bookingObject) => {
	const {adminToken} = retrieveTokens();
	const headers = {
		Authorization: adminToken,
	};
	const request = axios.patch(`${baseUrl}/update/${id}`, bookingObject, {headers});
	return request.then((response) => response.data);
};

export default {getBookings, getAll, create, remove, update};
