/**
 * The Homepage function renders the homepage of the application. It has two main states: showEmailForm
 * and showOtpForm. The showEmailForm state is toggled when the user clicks on either the New User or
 * Existing User button. The showOtpForm state is toggled when the user clicks on the Send OTP button.
 * The Homepage function also has two main functions: handleRegister and handleVerifyEmail. The
 * handleRegister function is called when the user clicks on the Send OTP button after clicking on the
 * New User button. The handleVerifyEmail function is called when the user clicks on the Send OTP
 * button after clicking on the Existing User button
 * @returns a JSX element.
 */
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import homePageService from '../../services/homePage';
import logo from '../../assets/hospital_logo.svg';
import Notification from '../Notification';

const Homepage = () => {
	const [email, setEmail] = useState('');
	const [showEmailForm, setShowEmailForm] = useState(false);
	const [showOtpForm, setShowOtpForm] = useState(false);
	const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
	const [showFooter, setShowFooter] = useState(true);
	const [otp, setOtp] = useState('');
	const [msg, setMsg] = useState('');
	const [error, setError] = useState(false);
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;

	const navigate = useNavigate();

	const handleUserClick = () => {
		setShowTermsAndConditions(true);
		setShowFooter(false);
	};

	const handleAgreeTermsAndConditions = () => {
		setShowEmailForm(true);
		setShowFooter(true);
	};

	// Verify existing user
	const handleVerifyEmail = async () => {
		setMsg('');
		setError(false);
		setMsg('Loading....');
		const data = {email: email};
		homePageService
			.sendOtp(data)
			.then((response) => {
				setMsg('');
				setShowOtpForm(true);
			},
			)
			.catch((error) => {
				const code = error.response.status;

				if (code === 404) {
					setMsg('');
					setError(false);
					setMsg('User not found.');
					setError(true);
					setTimeout(() => {
						setMsg('');
						setError(false);
					}, 5000);
				} else {
					setMsg('');
					setError(false);
					setMsg('An error occurred, please try again.');
					setError(true);
					setTimeout(() => {
						setMsg('');
						setError(false);
					}, 5000);
				}
			});
	};

	// Verify OTP
	const handleVerifyOtp = async () => {
		const data = {email: email, otp: otp};
		homePageService
			.verify(data)
			.then((response) => {
				// remove admin token and admin from local storage in case admin was not logged out
				window.localStorage.removeItem('admin');
				window.localStorage.removeItem('adminToken');

				window.localStorage.setItem('userToken', response.userToken);
				window.localStorage.setItem('userId', response.userId);
				window.localStorage.setItem('name', response.name);
				window.localStorage.setItem('dept', response.dept);
				window.localStorage.setItem('contactNo', response.contactNo);
				// Verification successful, redirect to bookings dashboard
				navigate(`/${brandIdentifier}${brandPages.userDashboard}`);
			})
			.catch(() => {
				setMsg('');
				setError(false);
				setMsg('Incorrect or expired OTP. Please try again.');
				setError(true);
				setTimeout(() => {
					setMsg('');
					setError(false);
				}, 5000);
			});
	};

	const renderUserButtons = () => {
		return (
			<>
				<div className="container text-center">
					<button className='btn light-btn btn-lg btn-block' onClick={handleUserClick}>
						<span className="btn-text" id="exist">Log In</span>
					</button>
				</div>
			</>
		);
	};

	// Render email form
	const renderEmailField = () => {
		return (
			<>
				{showEmailForm && (
					<div className="container text-center">
						<div className="mx-auto" style={{maxWidth: '400px'}}>
							<input
								id='email-field'
								type="email"
								className="form-control text-center"
								value={email}
								placeholder="Corporate Email Address"
								onChange={(e) => setEmail(e.target.value)}
								style={{height: '50px'}}
								required
							/>
						</div>
						<br />
						<button
							className="btn dark-btn btn-lg btn-block"
							id="otp"
							onClick={handleVerifyEmail}
						>
							<span className="btn-text">Send OTP</span>
						</button>
					</div>
				)}
			</>
		);
	};

	const isMobile = window.innerWidth <= 768;

	const renderTermsAndConditions = () => {
		return (
			<>
				{showTermsAndConditions && (
					<div className="container">
						<div className="mx-auto" style={{maxWidth: '700px'}}>
							<div
								style={{
									height: isMobile ? '210px' : '500px',
									overflowY: 'scroll',
									overflowX: 'hidden',
								}}
							>
								{/* eslint-disable */}
								<p>
									<strong>
										Terms and Conditions
									</strong>
								</p>
								<div>
									<u>Booking and Confirmation</u>
									<ol>
										<li>Room bookings can be made up to 180 days in advance.</li>
										<li>Rooms are available for booking between 8:00am and 6:30pm, excluding Sundays and Public Holidays.</li>
										<li>All room bookings are subject to approval from the administrator.</li>
										<li>Users are not permitted to sublet or transfer the usage of the room to individuals outside of IHH Healthcare.</li>
										<li>Users are advised to adhere to the maximum capacity of the room to ensure compliance with fire code regulations.</li>
									</ol>
									<u>Arrival and Departure</u>
									<ol>
										<li>Upon booking confirmation, users will receive a confirmation email containing a QR code and pin code for room access.</li>
										<li>Users must scan the assigned QR code or enter the pin code provided to access the room.</li>
										<li>The room will be accessible throughout the booking period. Outside of this period, the room will be locked.</li>
										<li>Users are responsible to do their own room setup and revert back to original setting once their sessions have ended.</li>
										<li>Users are responsible for conducting a handover check and ensuring that all equipment and room settings are returned to their original state.</li>
										<li>Users are liable for any repair and cleaning costs resulting from damages to the facility caused by the event.</li>
										<li>Users are expected to vacate the room on time, to allow other users to begin their sessions promptly.</li>
									</ol>
									<u>Safety and Maintenance</u>
									<ol>
										<li>Users are not allowed to decorate the room for their event without prior approval from the administrator.</li>
										<li>Users are prohibited from affixing anything to the walls or floor of the room.</li>
										<li>Users are responsible for maintaining an appropriate noise level to avoid causing disturbance to adjacent units’ operations (i.e. Wards or Clinics).</li>
										<li>Administrator reserves the right to terminate the event and cancel the room booking if necessary.</li>
										<li>Users shall not attempt to exercise control over the staff supporting MNH's room bookings. Any disputes or misbehaviour should be reported to the administrator via mnh.roombookingsupport@mountelizabeth.com.sg.</li>
										<li>Users must report any injuries to personnel(s) or damages to the room to the administrator immediately via mnh.roombookingsupport@mountelizabeth.com.sg.</li>
										<li>Do note that CCTV cameras are deployed to monitor the facility 24/7, including the recording of live video footage.</li>
									</ol>
									<u>Cancellation and No Show</u>
									<ol>
										<li>Users must notify the administrator in advance at mnh.roombookingsupport@mountelizabeth.com.sg in case of any booking cancellations.</li>
										<li>In the event of multiple cases with no-show, the hospital management reserves the right to bar the user from making future room bookings.</li>
									</ol>
								</div>
							</div>
						</div>
						<div className='text-center mt-3'>
							<button
								className="btn light-btn btn-lg btn-block"
								id="agree-tc"
								onClick={handleAgreeTermsAndConditions}
							>
								<span className="btn-text">I Agree</span>
							</button>
						</div>
					</div>
				)}
			</>
		);
	};


	// Render OTP form
	const renderOtpField = () => {
		return (
			<>
				<div className="container text-center">
					<div className="mx-auto" style={{maxWidth: '400px'}}>
						<input
							id='otp-field'
							type="text"
							className="form-control text-center"
							value={otp}
							placeholder="Enter OTP"
							onChange={(e) => setOtp(e.target.value)}
							style={{height: '50px'}}
							required
						/>
					</div>
					<p id='otp-text' className='my-3'>
						OTP was sent to your corporate email address.
					</p>
					<button className="btn dark-btn btn-lg btn-block" id="login" onClick={handleVerifyOtp}>
						<span className="btn-text">Login</span>
					</button>
				</div>
				<div className="container text-center">
					{msg && <Notification message={msg} isError={error} />}
				</div>
			</>
		);
	};

	// Render homepage
	return (
		<div>
			<div className='d-flex justify-content-end my-4 me-3'>
			</div>
			<div className='d-flex justify-content-between align-items-center'>
				<div className='d-flex flex-grow-1 justify-content-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block' width={400} height={70}/>
					</div>
				</div>
			</div>
			<div>
				<h1 className='text-center mt-4'>Conference and Meeting Rooms Booking System</h1>
			</div>
			<br /> <br />
			{/* Toggles between email field, otp field, user buttons or terms and conditions */}
			{showOtpForm ? (
				renderOtpField()
			) : (
				<>
					{showEmailForm ? (
						renderEmailField()
					) : (
						<>
							{ showTermsAndConditions ? (
								renderTermsAndConditions()
							) : (
								renderUserButtons()
							)}
						</>
					)}
					<br />
					<div className="container text-center">
						{msg && <Notification message={msg} isError={error} />}
					</div>
				</>
			)}
			{showFooter && (
				<footer style={{position: 'fixed', bottom: 0, width: '100%'}}>
					<hr />
					<div className='text-center mt-4 mb-4 mx-2'>
						{/* eslint-disable-next-line */}
						<p id='footer-text'>Please contact <a href="mailto:mnh.roombookingsupport@mountelizabeth.com.sg">mnh.roombookingsupport@mountelizabeth.com.sg</a> if you have any questions or inquiries</p>
					</div>
				</footer>
			)}
		</div>
	);
};

export default Homepage;
