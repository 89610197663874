/**
 * This is a React component that displays a list of active users, allows the user to add new users,
 * and provides the ability to edit user information.
 * @returns The Users component is being returned, which renders a table of active users with their
 * information (name, designation, department, email, contact number) and an option to edit their
 * information. The component also includes a button to log out and a button to add a new user. The
 * email and contact number are masked with asterisks except when hovered over, in which case the full
 * value is displayed.
 */
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import UserService from '../../services/users';
import logo from '../../assets/hospital_logo.svg';
import NavHome from '../NavHome';
import Pagination from 'react-bootstrap/Pagination';

const Users = () => {
	const [users, setUsers] = useState([]);
	const [hoveredEmail, setHoveredEmail] = useState(null);
	const [hoveredContactNo, setHoveredContactNo] = useState(null);
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;
	const navigate = useNavigate();

	useEffect(() => {
		UserService.getAll()
			.then((response) => {
				setUsers(response);
			});
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const maskEmail = (email) => {
		const [username, domain] = email.split('@');
		const maskedUsername = username.slice(0, Math.floor(username.length / 2)) + '*'.repeat(username.length - Math.floor(username.length / 2));
		return maskedUsername + '@' + domain;
	};

	const maskContactNo = (contactNo) => {
		return String(contactNo).slice(0, String(contactNo).length - 4) + '*'.repeat(4);
	};

	const handleEmailHover = (id) => {
		setHoveredEmail(id);
	};

	const handleContactNoHover = (id) => {
		setHoveredContactNo(id);
	};

	const handleMouseLeave = () => {
		setHoveredEmail(null);
		setHoveredContactNo(null);
	};

	// To display pagination of users
	const itemsPerPage = 100;
	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	// Separate deactivated and active users into separate arrays and sort active users alphabetically
	// const deactivatedUsers = users.filter((user) => user.deactivated); // TODO: Future possible function to show deactivated users in archive and reactivate them
	const activeUsers = users
		.filter((user) => !user.deactivated)
		.sort((a, b) => {
			// Sort by department (primary)
			if (a.dept < b.dept) {
				return -1;
			}
			if (a.dept > b.dept) {
				return 1;
			}

			// Sort by name (secondary) in alphabetical order
			return a.name.localeCompare(b.name);
		});

	// Pagination slice of active users
	const usersToDisplay = activeUsers.slice(startIndex, endIndex);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<div>
			<div className='d-flex justify-content-end my-3 me-3'>
				<div className="me-3 mt-1">
					<NavHome />
				</div>
				<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block mx-auto' width={400} height={70}/>
					</div>
				</div>
			</div>
			<h1 className="text-center mt-4 mb-2">User List</h1>
			<div className="d-flex justify-content-center justify-content-md-end me-md-5 pe-md-5">
				<button className="btn get-code-btn mb-4 mt-2" onClick={() => navigate(`/${brandIdentifier}${brandPages.adminUserForm}`)}>Add User</button>
			</div>
			<div className="table-responsive">
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Designation</th>
							<th>Department</th>
							<th>Email</th>
							<th>Contact Number</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{usersToDisplay && usersToDisplay.map((user) => (
							<tr key={user.id}>
								<td>{user.name}</td>
								<td>{user.designation}</td>
								<td>{user.dept}</td>
								<td style={{maxWidth: '700px'}}>
									<span
										className="hoverable-value"
										onMouseEnter={() => handleEmailHover(user.id)}
										onMouseLeave={handleMouseLeave}
									>
										{/* eslint-disable-next-line */}
										{hoveredEmail === user.id ? user.email : maskEmail(user.email)}
									</span>
								</td>
								<td>
									<span
										className="hoverable-value"
										onMouseEnter={() => handleContactNoHover(user.id)}
										onMouseLeave={handleMouseLeave}
									>
										{/* eslint-disable-next-line */}
										{hoveredContactNo === user.id ? user.contactNo : maskContactNo(user.contactNo)}
									</span>
								</td>
								<td>
									<button
										className="btn btn-link no-hover edit-info-btn"
										onClick={() => navigate(`/${brandIdentifier}${brandPages.adminUserForm}`, {state: {user}})}
									>
										Edit Info
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Pagination className="my-pagination justify-content-center mt-4">
				<Pagination.Prev
					onClick={() => handlePageChange(currentPage - 1)}
					disabled={currentPage === 1}
				/>
				{Array(Math.ceil(activeUsers.length / itemsPerPage))
					.fill()
					.map((_, index) => (
						<Pagination.Item
							key={index + 1}
							active={index + 1 === currentPage}
							onClick={() => handlePageChange(index + 1)}
						>
							{index + 1}
						</Pagination.Item>
					))}
				<Pagination.Next
					onClick={() => handlePageChange(currentPage + 1)}
					disabled={
						currentPage === Math.ceil(activeUsers.length / itemsPerPage)
					}
				/>
			</Pagination>
		</div>
	);
};

export default Users;
