/**
 * It checks if the user is an admin or not, and if they are, it returns a link to the admin dashboard,
 * otherwise it returns a link to the user dashboard
 * @returns A link to the admin or user dashboard depending on the user's role.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {FaHome} from 'react-icons/fa';

const HomeIcon = () => {
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;
	const isAdmin = localStorage.getItem('admin');

	return (
		<Link to={isAdmin === 'admin' ? `/${brandIdentifier}${brandPages.adminDashboard}` : `/${brandIdentifier}${brandPages.userDashboard}`}>
			<FaHome className='fa-home'/>
		</Link>
	);
};

export default HomeIcon;
