/* This code is defining a module that exports three functions: `register`, `verify`, and `sendOtp`.
These functions use the Axios library to make HTTP requests to an API. The `baseUrl` variable is
used to construct the URLs for the API endpoints. The `register` function sends an email to the API
to register a user, the `verify` function sends an object containing an OTP (one-time password) to
the API to verify a user, and the `sendOtp` function sends an email to the API to request an OTP.
The module is exported using the `export default` syntax, which allows other modules to import and
use these functions. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'users';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const register = (email) => {
	const request = axios.post(`${baseUrl}/register`, email);
	return request.then((response) => response.data);
};

const verify = (verifyObject) => {
	const request = axios.post(`${baseBrandUrl}/verify-otp`, verifyObject);
	return request.then((response) => response.data);
};

const sendOtp = (email) => {
	const request = axios.post(`${baseBrandUrl}/send-otp`, email);
	return request.then((response) => response.data);
};

export default {register, verify, sendOtp};
