/**
 * The BookingForm function is a React component that renders a
 * form for creating a new booking
 */
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import BookingFormJsx from './BookingForm.jsx';
import pendingBookingsService from '../../services/pendingBookings';
import bookingsService from '../../services/bookings';
import venuesService from '../../services/venues.js';
import turnoversService from '../../services/turnovers.js';
import availabilityService from '../../services/availability.js';

const BookingForm = () => {
	const [name, setName] = useState('');
	const [dept, setDept] = useState('');
	const [room, setRoom] = useState('');
	const [venueName, setVenueName] = useState('');
	const [date, setDate] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [purpose, setPurpose] = useState('');
	const [attendees, setAttendees] = useState('');
	const [remarks, setRemarks] = useState('');
	const [customLayout, setCustomLayout] = useState('');
	const [notification, setNotification] = useState('');
	const [error, setError] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [venues, setVenues] = useState([]);
	const [turnovers, setTurnovers] = useState([]);
	const [publicHolidays, setPublicHolidays] = useState([]);
	const [requestPopupVisible, setRequestPopupVisible] = useState(false);
	const [availability, setAvailability] = useState({});
	const [equipmentRequest, setEquipmentRequest] = useState({});
	const [layoutRequest, setLayoutRequest] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [selectedLayout, setSelectedLayout] = useState('');

	const location = useLocation();
	// Retrieve venue id passed from select facility page
	useEffect(() => {
		const venueId = location.state.venue.id;
		setRoom(venueId);

		const admin = window.localStorage.getItem('admin');
		if (admin === 'admin') {
			setIsAdmin(true);
		}
	}, [location, isAdmin]);


	useEffect(() => {
		// Fetch bookings, venue, turnover data from server and update state
		Promise.all([
			bookingsService.getAll(),
			pendingBookingsService.getAll(),
			turnoversService.getAll(),
		]).then(([initialBookings, initialPendingBookings, initialTurnovers]) => {
			const filteredInitialBookings = initialBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			const filteredInitialPendingBookings = initialPendingBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			setBookings(filteredInitialBookings);
			setPendingBookings(filteredInitialPendingBookings);
			setTurnovers(initialTurnovers);
		});

		venuesService
			.getAll()
			.then((initialVenues) => {
				setVenues(initialVenues);
			});

		// Retrieve facility available days and hours to show on the calendar
		availabilityService
			.getAvailability()
			.then((response) => {
				const data = response;
				setAvailability(data);
				setPublicHolidays(data.holidaysToDisplay);
			});

		// Fetch user data from local storage and update state
		const userName = window.localStorage.getItem('name');
		setName(userName);
		const userDept = window.localStorage.getItem('dept');
		setDept(userDept);
		const userContactNumber = window.localStorage.getItem('contactNo');
		setContactNumber(userContactNumber);
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const handleDateSelect = (date) => {
		setDate(date);
	};


	const checkConflicts = () => {
		const conflicts = [];

		// iterate through all bookings, pending bookings and turnovers
		[...bookings, ...pendingBookings, ...turnovers].forEach((booking) => {
			const bookingStart = new Date(`${booking.date}T${booking.startTime}:00Z`); // start of booking being checked
			const bookingEnd = new Date(`${booking.date}T${booking.endTime}:00Z`); // end of booking being checked
			const currentStart = new Date(`${date}T${startTime}:00Z`); // start of current booking
			const currentEnd = new Date(`${date}T${endTime}:00Z`); // end  of current booking

			// Retrieve the turnover time for the venue
			const venueTurnoverTime = booking.venue.turnoverTime;

			// Calculate the end time with turnover time
			const currentEndWithTurnover = new Date(currentEnd.getTime());
			if (venueTurnoverTime !== 0) {
				// eslint-disable-next-line max-len
				currentEndWithTurnover.setMinutes(currentEnd.getMinutes() + (venueTurnoverTime * 60));
			}

			// Check for conflicts with holidays
			publicHolidays.forEach((holiday) => {
				const holidayStart = new Date(`${holiday.startDate}T00:00:00Z`);
				const holidayEnd = new Date(`${holiday.endDate}T00:00:00Z`);

				if ( !isAdmin && (
					(currentStart >= holidayStart && currentStart <= holidayEnd) ||
					(currentEnd >= holidayStart && currentEnd <= holidayEnd) ||
					(currentStart <= holidayStart && currentEnd >= holidayEnd)
				)) {
					conflicts.push(booking);
				}
			});

			// check for conflicts and add to conflicts array
			if (
				booking.venue.id === room &&
				(
					// checks if current booking start time conflicts with another booking
					(currentStart >= bookingStart && currentStart < bookingEnd) ||
					// checks if current booking end time conflicts with another booking
					// eslint-disable-next-line max-len
					(currentEndWithTurnover > bookingStart && currentEndWithTurnover <= bookingEnd) ||
					// checks if current booking is within another booking
					(currentStart <= bookingStart && currentEndWithTurnover >= bookingEnd)
				)
			)	{
				conflicts.push(booking);
			}
		});
		return conflicts;
	};

	// check for conflicts when date, start time or end time is changed
	useEffect(() => {
		const conflicts = checkConflicts();
		if (conflicts.length > 0) {
			setNotification('');
			setError(false);
			setNotification(`Your selected booking is unavailable. 
				Please check the calendar and venue specific end time requirements.`);
			setError(true);
			setTimeout(() => {
				setNotification('');
				setError(false);
			}, 8000);
		}
	}, [date, startTime, endTime, bookings, pendingBookings, turnovers, room]);


	// handle form validation
	const validateForm = (date, startTime, endTime) => {
		const current = new Date();
		const [year, month, day] = date.split('-');
		const [hour, min] = startTime.split(':');
		const openingTime = new Date(year, month - 1, day, START_TIME, 0);
		// eslint-disable-next-line max-len
		const closingTime = new Date(year, month - 1, day, START_TIME + NUM_TIME_SLOTS / 2, 0);
		const [endHour, endMin] = endTime.split(':');
		const bookingDateTime = new Date(year, month - 1, day, hour, min);
		const endDateTime = new Date(year, month - 1, day, endHour, endMin);

		// check conflicts with existing bookings
		const conflicts = checkConflicts();
		if (conflicts.length > 0) {
			return {
				success: false,
				message: 'Error: Your selected booking is unavailable. Please check the calendar.',
			};
		}

		// check if the time is in 30-minute blocks
		if (min % 30 !== 0 || endMin % 30 !== 0) {
			return {
				success: false,
				message: 'Error: Start and end timing must be in 30-minute blocks.',
			};
		}

		// check if the booking date and time is in the future
		if (bookingDateTime <= current) {
			return {
				success: false,
				message: 'Error: Booking date and time must be in the future',
			};
		}

		// check if end time is later than start time
		if (endDateTime <= bookingDateTime) {
			return {
				success: false,
				message: 'Error: End time must be later than start time',
			};
		}

		// check if the booking time is within opening hours (for non-admin users)
		// eslint-disable-next-line max-len
		if (!isAdmin && (bookingDateTime < openingTime || endDateTime > closingTime)) {
			return {
				success: false,
				message: 'Error: Booking time must be within opening hours',
			};
		}

		// check if the booking date is not a Sunday (for non-admin users)
		if (!isAdmin && bookingDateTime.getDay() === 0) {
			return {
				success: false,
				message: 'Error: Booking date cannot be on a Sunday',
			};
		}

		// check if date selected exceeds max days in advance
		const chosenDate = new Date(date);
		if (!isAdmin && chosenDate > futureDate) {
			return {
				success: false,
				message: `Error: Booking date must be before ${futureDate.toString().slice(0, 15)}`,
			};
		}

		return {success: true};
	};

	// Handle form submission
	const handleFormSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true); // disable submit button

		// check if the form is valid
		const validation = validateForm(date, startTime, endTime);
		if (!validation.success) {
			setNotification('');
			setError(false);
			setNotification(validation.message);
			setError(true);
			setIsSubmitting(false); // enable submit button
			setTimeout(() => {
				setNotification('');
				setError(false);
			}, 5000);
			return;
		}

		const pendingBookingObject = {
			name: name,
			dept: dept,
			contactNumber: contactNumber,
			venue: room,
			date: date,
			startTime: startTime,
			endTime: endTime,
			purpose: purpose,
			equipmentList: equipmentRequest,
			layout: layoutRequest,
			attendees: attendees,
			remarks: remarks,
			customLayout: customLayout,
			user: window.localStorage.getItem('userId'),
		};

		// create a new booking if is admin else create a new pending booking
		if (isAdmin) {
			bookingsService
				.create(pendingBookingObject)
				.then(() => {
					setRequestPopupVisible(true);
				})
				.catch((error) => {
					setNotification('');
					setError(false);
					setNotification('Error: Booking Not Available');
					setError(true);
					setIsSubmitting(false); // enable submit button
					setTimeout(() => {
						setNotification(null);
						setError(false);
						console.log(error);
					}, 3000);
				});
		} else {
			pendingBookingsService
				.create(pendingBookingObject)
				.then(() => {
					setRequestPopupVisible(true);
				})
				.catch((error) => {
					setNotification('');
					setError(false);
					setNotification('Error: Booking Not Available');
					setError(true);
					setIsSubmitting(false); // enable submit button
					setTimeout(() => {
						setNotification(null);
						setError(false);
						console.log(error);
					}, 3000);
				});
		}

		// get venue name from venue id
		venuesService
			.getVenue(room)
			.then((venue) => {
				setVenueName(venue.name);
			});
	};

	// only allow digits in contact number field
	const handleContactNumberChange = (event) => {
		const inputValue = event.target.value;
		const regex = /^\d*$/; // Only allow digits
		if (regex.test(inputValue)) {
			setContactNumber(inputValue);
		}
	};

	// handle equipment quantity change when user clicks on the + or - button
	const handleEquipmentQuantityChange = (event, equipmentName) => {
		const maxEquipmentQuantity = venues.find((venue) =>
			venue.id === room).equipmentList[equipmentName];
		const currentEquipmentQuantity = equipmentRequest[equipmentName] || 0;
		let updatedEquipmentQuantity;

		// check if user clicked on the + or - button and update the quantity.
		if (event.target.name === 'minus') {
			updatedEquipmentQuantity = Math.max(currentEquipmentQuantity - 1, 0);
		} else {
			// eslint-disable-next-line max-len
			updatedEquipmentQuantity = Math.min(currentEquipmentQuantity + 1, maxEquipmentQuantity);
		}

		// update the equipmentRequest object if the updated quantity is not 0
		const newEquipmentRequest = {...equipmentRequest};
		if (updatedEquipmentQuantity !== 0) {
			newEquipmentRequest[equipmentName] = updatedEquipmentQuantity;
		} else {
			// Remove the equipment from the state if quantity is 0
			delete newEquipmentRequest[equipmentName];
		}
		setEquipmentRequest(newEquipmentRequest);
	};

	// handle layout selection
	const handleLayoutChange = (event) => {
		setLayoutRequest(event.target.value);
		setSelectedLayout(event.target.value);
	};


	// setting the time options for the start and end time dropdown
	const NUM_TIME_SLOTS = availability.NUM_TIME_SLOTS;
	const TIME_SLOT_LENGTH_IN_MINUTES = availability.TIME_SLOT_LENGTH_IN_MINUTES;
	const START_TIME = availability.START_TIME;

	const timeOptions = Array.from({length: NUM_TIME_SLOTS}, (_, i) => {
		const hour = Math.floor(i / 2) + START_TIME; // start at 9 AM
		const minute = (i % 2) * TIME_SLOT_LENGTH_IN_MINUTES; // 0 or 30
		// eslint-disable-next-line max-len
		return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
	});

	// setting max future date that is bookable
	const currentDate = new Date();
	const futureDate = new Date();
	futureDate.setDate(currentDate.getDate() + availability.maxBookingDays);


	// handle event click on calendar
	const handleEventClick = (eventClickInfo) => {
		const selectedBookingID = eventClickInfo.event._def.extendedProps.id;

		if (selectedBookingID) {
			// find the booking/pending booking with the selected ID
			const bookingEvent = bookings.find((booking) =>
				booking.id === selectedBookingID);
			const pendingBookingEvent = pendingBookings.find((booking) =>
				booking.id === selectedBookingID);
			const selectedEvent = bookingEvent || pendingBookingEvent;

			setSelectedBooking(selectedEvent);
			setShowBookingDetailsPopup(true);
		}
	};

	// close the booking details popup
	const onClose = () => {
		setShowBookingDetailsPopup(false);
		setSelectedBooking(null);
	};

	return (
		<BookingFormJsx
			name={name}
			dept={dept}
			room={room}
			venueName={venueName}
			venues={venues}
			date={date}
			startTime={startTime}
			endTime={endTime}
			contactNumber={contactNumber}
			purpose = {purpose}
			equipmentRequest={equipmentRequest}
			notification={notification}
			attendees={attendees}
			turnovers={turnovers}
			remarks={remarks}
			isAdmin={isAdmin}
			isSubmitting={isSubmitting}
			availability={availability}
			futureDate={futureDate.toString().slice(0, 15)}
			customLayout={customLayout}
			handleFormSubmit={handleFormSubmit}
			handleContactNumberChange={handleContactNumberChange}
			handleDateSelect={handleDateSelect}
			handleEquipmentQuantityChange={handleEquipmentQuantityChange}
			handleLayoutChange={handleLayoutChange}
			setName={setName}
			setDept={setDept}
			setRoom={setRoom}
			setDate={setDate}
			setStartTime={setStartTime}
			setEndTime={setEndTime}
			setPurpose={setPurpose}
			setRemarks={setRemarks}
			setCustomLayout={setCustomLayout}
			setAttendees={setAttendees}
			bookings={bookings}
			pendingBookings={pendingBookings}
			error={error}
			requestPopupVisible={requestPopupVisible}
			logOut={logOut}
			timeOptions={timeOptions}
			showBookingDetailsPopup={showBookingDetailsPopup}
			onClose={onClose}
			onEventClick={handleEventClick}
			selectedBooking={selectedBooking}
			selectedLayout={selectedLayout}
		/>
	);
};

export default BookingForm;
