import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'devices';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const getDeviceStatus = (credentials) => {
	const config = {
		headers: {
			'Authorization': 'Basic ' + btoa(credentials + ':'),
		},
	};

	const request = axios.get(baseBrandUrl + '/device/status/get', config);
	return request.then((response) => response.data);
};

export default {getDeviceStatus};
