/* This code is defining a module that exports a function called `getAll` which retrieves data from an
API endpoint using the Axios library. The `baseUrl` variable is the base URL for the API endpoint,
and the `retrieveTokens` function retrieves authentication tokens from local storage. The `getAll`
function uses these tokens to set the `Authorization` header in the request configuration object,
and then sends a GET request to the API endpoint using Axios. Finally, the function returns a
promise that resolves to the response data from the API. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'turnovers';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const retrieveTokens = () => {
	const admin = window.localStorage.getItem('adminToken');
	const user = window.localStorage.getItem('userToken');

	const adminToken = `Bearer ${admin}`;
	const userToken = `Bearer ${user}`;
	return {adminToken, userToken};
};

const getAll = () => {
	const {userToken} = retrieveTokens();

	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.get(baseBrandUrl, config);
	return request.then((response) => response.data);
};

export default {getAll};
