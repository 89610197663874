/* This is a module that exports several functions for making API requests related to bookings. It uses
the Axios library to make HTTP requests and retrieves tokens from local storage to include in the
request headers for authentication. The functions include `getAll` to retrieve all bookings,
`create` to create a new booking, `update` to update an existing booking, `getCode` to retrieve a QR
code for a booking, and `updatePurpose` to update the purpose of a booking. The `baseUrl` variable
is set to the API base URL plus the "bookings" endpoint. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'bookings';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const retrieveTokens = () => {
	const admin = window.localStorage.getItem('adminToken');
	const user = window.localStorage.getItem('userToken');

	const adminToken = `Bearer ${admin}`;
	const userToken = `Bearer ${user}`;
	return {adminToken, userToken};
};

const getAll = (getAll) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};

	const getAllRequest = getAll || 'false';
	const request = axios.get(baseBrandUrl + `/${getAllRequest}`, config);
	return request.then((response) => response.data);
};

const create = (bookingObject) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.post(baseBrandUrl, bookingObject, config);
	return request.then((response) => response.data);
};

const update = (id, bookingsObject) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	try {
		const request = axios.patch(`${baseUrl}/${id}`, bookingsObject, config);
		return request.then((response) => response.data);
	}	catch (error) {
		console.log(error);
	}
};

const getCode = (id, isForward, email) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};

	const isForwardRequest = isForward || 'false';
	const forwardEmail = email || 'nil';
	const request = axios.get(`${baseUrl}/qr/generate/${id}/${isForwardRequest}/${forwardEmail}`, config);
	return request.then((response) => response.data);
};

const updatePurpose = (id, purpose) => {
	const {adminToken} = retrieveTokens();
	const config = {
		headers: {Authorization: adminToken},
	};

	const request = axios.patch(`${baseUrl}/${id}/purpose`, purpose, config);
	return request.then((response) => response.data);
};

const updateTimings = (id, timings) => {
	const {adminToken} = retrieveTokens();
	const config = {
		headers: {Authorization: adminToken},
	};

	const request = axios.patch(`${baseBrandUrl}/${id}/time`, timings, config);
	return request.then((response) => response.data);
};

export default {getAll, create, update, getCode, updatePurpose, updateTimings};
