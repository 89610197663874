import React, {useState} from 'react';
import deviceService from '../../services/devices';

const DeviceStatus = () => {
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [username, setUsername] = useState('');
	const [devices, setDevices] = useState([]);

	const authCheck = () => {
		deviceService
			.getDeviceStatus(username)
			.then((initialDevices) => {
				setIsAuthorized(true);
				setDevices(initialDevices);
			});
	};

	return (
		<>
			{!isAuthorized ? (
				<div className='form-group mt-5 ms-5'>
					<input type='password' style={{height: '38px'}} value={username} onChange={(e) => setUsername(e.target.value)}/>
					<button type="button" className="btn btn-primary ms-2" onClick={() => authCheck(username)}>Submit</button>
				</div>
			) : (
				<div className="device-status">
					<h1 className='mt-3 ms-3'>Device Status</h1>
					<div className="device-status__container mt-5 ms-3">
						{devices.map((device) => (
							<div className="device-status__card" key={device.deviceID}>
								<h2>{device.deviceID}</h2>
								<h5>{device.venueAssigned.name}</h5>
								<h5 className={`status-${device.status.toLowerCase().replace(' ', '-')}`}>
									<strong>{device.status}</strong>
								</h5>
								<p>Last Seen: {device.lastSeen}</p>
								<hr />
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default DeviceStatus;
