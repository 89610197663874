/**
 * It returns a div with a class of either error or success, depending on the value of the isError
 * prop, and a p element with the value of the message prop
 * @returns A React component
 */
import React from 'react';
import PropTypes from 'prop-types';

const Notification = ({message, isError}) => {
	const className = `alert ${isError ? 'alert-danger' : 'alert-success'} text-center my-${isError ? '3' : '2'} mx-auto w-75`;

	return (
		<div className={className} role="alert">
			<p>{message}</p>
		</div>
	);
};

Notification.propTypes = {
	message: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
};

export default Notification;
