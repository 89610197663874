/**
 * It renders a table of the user's current bookings and allows user to make
 * new bookings and view all bookings.
 * @returns A table of the current bookings.
 */
import {useState, useEffect, React} from 'react';
import {Link} from 'react-router-dom';
import usersService from '../../services/users';
import pendingBookingsService from '../../services/pendingBookings';
import bookingsService from '../../services/bookings';
import CancelPopup from '../CancelPopup';
import CodePopup from '../CodePopup';
import DetailsPopup from '../DetailsPopup';
import ForwardPopup from '../forwardPopup';
import logo from '../../assets/hospital_logo.svg';
import NavHome from '../NavHome';
import Pagination from 'react-bootstrap/Pagination';


const BookingsDashboard = () => {
	const [currentBookings, setCurrentBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [cancelPopupVisible, setCancelPopupVisible] = useState(false);
	const [cancelledBooking, setCancelledBooking] = useState(null);
	const [codes, setCodes] = useState(null);
	const [codePopupVisible, setCodePopupVisible] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
	const [forwardPopupVisible, setForwardPopupVisible] = useState(false);
	const [layout, setLayout] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [userName, setUserName] = useState('');
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;

	useEffect(() => {
		// Fetch bookings data from server and update state of current bookings
		const userId = window.localStorage.getItem('userId');
		setUserName(window.localStorage.getItem('name'));

		usersService
			.getBookings(userId)
			.then((user) => {
				const now = new Date();
				setPendingBookings(user.pendingBookings);
				/* Filtering the bookings to only show the ones that are in the future. */
				const allBookings = [...user.bookings, ...user.pendingBookings];
				const newCurrentBookings = allBookings.filter((booking) => {
					const dateString = `${booking.date}T${booking.endTime}:00`;
					const bookingDateTime = new Date(dateString);

					return bookingDateTime >= now;
				});
				setCurrentBookings(newCurrentBookings);
			});
		window.localStorage.getItem('admin') === 'admin' ? setIsAdmin(true) : setIsAdmin(false);
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const cancelBooking = (booking) => {
		setCancelledBooking(booking);
		setCancelPopupVisible(true);
	};

	// This function is called when the user clicks the "Cancel" button in the
	// cancel popup. It updates the status of the booking to "Cancelled" and
	// closes the popup.
	const handleCancelConfirmation = () => {
		const bookingId = cancelledBooking.id;
		const bookingToRemove = currentBookings.find(
			(booking) => booking.id === bookingId,
		);

		if (
			bookingToRemove &&
            pendingBookings.some((booking) => booking.id === bookingToRemove.id)
		) {
			pendingBookingsService
				.update(bookingId, {status: 'Cancelled', cancelledReason: 'User Cancelled'})
				.then(() => {
					// update state to reflect the change
					const userId = window.localStorage.getItem('userId');
					usersService
						.getBookings(userId)
						.then((user) => {
							const now = new Date();
							setPendingBookings(user.pendingBookings);
							/* Filtering the bookings to only show the ones that are in the future. */
							// eslint-disable-next-line max-len
							const allBookings = [...user.bookings, ...user.pendingBookings];
							const newCurrentBookings = allBookings.filter((booking) => {
								const dateString = `${booking.date}T${booking.endTime}:00`;
								const bookingDateTime = new Date(dateString);

								return bookingDateTime >= now;
							});
							setCurrentBookings(newCurrentBookings);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			bookingsService
				.update(bookingId, {status: 'Cancelled', cancelledReason: 'User Cancelled'})
				.then(() => {
					// update state to reflect the change
					const userId = window.localStorage.getItem('userId');
					usersService
						.getBookings(userId)
						.then((user) => {
							const now = new Date();
							setPendingBookings(user.pendingBookings);
							/* Filtering the bookings to only show the ones that are in the future. */
							// eslint-disable-next-line max-len
							const allBookings = [...user.bookings, ...user.pendingBookings];
							const newCurrentBookings = allBookings.filter((booking) => {
								const dateString = `${booking.date}T${booking.endTime}:00`;
								const bookingDateTime = new Date(dateString);

								return bookingDateTime >= now;
							});
							setCurrentBookings(newCurrentBookings);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}

		setCancelledBooking(null);
		setCancelPopupVisible(false);
	};

	const handleCancelPopupClose = () => {
		setCancelledBooking(null);
		setCancelPopupVisible(false);
	};

	// generate the QR code for the booking
	const getCodes = (bookingId) => {
		bookingsService
			.getCode(bookingId)
			.then((response) => {
				setCodes(response);
				setCodePopupVisible(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleCodePopupClose = () => {
		setCodes(null);
		setCodePopupVisible(false);
	};

	// open the details popup
	const handleDetailsPopup = (booking) => {
		setSelectedBooking(booking);
		// Check if the setup value is defined before setting it in state
		if (booking.venue.layout && booking.venue.layout[booking.layout]) {
			const setup = booking.venue.layout[booking.layout].setup;
			setLayout(setup);
		} else {
			setLayout('NIL');
		}
		setDetailsPopupVisible(true);
	};

	// close the details popup
	const handleDetailsClose = () => {
		setSelectedBooking(null);
		setDetailsPopupVisible(false);
	};

	const handleForwardPopup = (booking) => {
		setSelectedBooking(booking);
		setForwardPopupVisible(true);
	};

	const handleForwardPopupClose = () => {
		setSelectedBooking(null);
		setForwardPopupVisible(false);
	};

	const getStatusStyle = (status) => {
		switch (status) {
		case 'Approved':
			return {color: '#2A9D8F', fontWeight: 'bold'};
		case 'Cancelled':
		case 'Denied':
			return {color: '#E76F51', fontWeight: 'bold'};
		default:
			return {};
		}
	};


	// To display pagination of bookings
	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	// sort bookings by date from earliest to latest
	const sortedBookings = currentBookings
		.sort((a, b) => new Date(a.date) - new Date(b.date));

	const bookingsToDisplay = sortedBookings.slice(startIndex, endIndex);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const tableHeaders = ['Venue', 'Date', 'Start Time', 'End Time', 'Details', 'Status', 'Action'];

	const renderBookingsTable = () => {
		if (currentBookings.length === 0) {
			return <p>No current bookings found.</p>;
		}

		return (
			<div>
				<div className='table-responsive'>
					<table className="table mx-auto">
						<thead>
							<tr>
								{tableHeaders.map((header) => (
									<th key={header}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{bookingsToDisplay
								.map((booking) => {
									/* Checking if the booking is pending or approved. */
									// eslint-disable-next-line
									const isPending = pendingBookings.some((pendingBooking) =>
										pendingBooking.id === booking.id);
									const isDenied = booking.status === 'Denied';
									const isCancelled = booking.status === 'Cancelled';
									const status = isCancelled ? 'Cancelled' : isDenied ? 'Denied' : isPending ? 'Pending' : 'Approved';
									const statusStyle = getStatusStyle(status);

									// Parse the date string and format it in dd-mm-yyyy format
									const date = new Date(booking.date);
									const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});

									/* Checking if the status of the booking is pending or approved. If it
									is, it will display a cancel button. Otherwise, it will display a
									dash. */
									const cancelButton = status === 'Pending' || status === 'Approved' ? (
										<button
											className="btn border-dark"
											onClick={() => cancelBooking(booking)}
										>
											CANCEL BOOKING
										</button>
									) : (
										'-'
									);
									/* Check if booking start time is 60 minutes from the current time and display a button to get QR
									code accordingly */
									const isCodeAvailable = new Date(`${booking.date}T${booking.startTime}:00`) - new Date() <= 30 * 60 * 1000; // 30 minutes in milliseconds

									// Check if the venue name is "Conference Room 5 & 6" or "Glass Meeting Room @L2"
									const isExcludedVenue =
										booking.venue.name === 'Conference Room 5 & 6';

									// eslint-disable-next-line
									const qrCodeButton = !isPending && isCodeAvailable && !isExcludedVenue && !isDenied && !isCancelled ? (
										<button
											className="btn border-dark get-code-btn mb-2"
											onClick={() => getCodes(booking.id)}
										>
											GET QR/OTP
										</button>
									) : (
										''
									);
									{/* eslint-disable-next-line */}
									const forwardQRCode = isAdmin && !isCancelled && !isExcludedVenue ? (
										<button
											className="btn border-dark send-code-btn mb-2"
											onClick={() => handleForwardPopup(booking.id)}
										>
											FORWARD QR/OTP
										</button>
									) : (
										''
									);

									return (
										<tr key={booking.id}>
											<td>{booking.venue.name}</td>
											<td>{formattedDate}</td>
											<td>{booking.startTime}</td>
											<td>{booking.endTime}</td>
											<td>
												<button
													className='view-btn'
													onClick={
														() => handleDetailsPopup(booking)
													}
												>
													View
												</button>
											</td>
											<td style={statusStyle}>{status}</td>
											<td>
												<div>{qrCodeButton}</div>
												<div>{forwardQRCode}</div>
												<div>{cancelButton}</div>
											</td>
										</tr>
									);
								})
							}
						</tbody>
					</table>
				</div>
				<Pagination className="my-pagination justify-content-center mt-4">
					<Pagination.Prev
						onClick={() => handlePageChange(currentPage - 1)}
						disabled={currentPage === 1}
					/>
					{Array(Math.ceil(sortedBookings.length / itemsPerPage))
						.fill()
						.map((_, index) => (
							<Pagination.Item
								key={index + 1}
								active={index + 1 === currentPage}
								onClick={() => handlePageChange(index + 1)}
							>
								{index + 1}
							</Pagination.Item>
						))}
					<Pagination.Next
						onClick={() => handlePageChange(currentPage + 1)}
						disabled={
							currentPage === Math.ceil(sortedBookings.length/itemsPerPage)
						}
					/>
				</Pagination>
			</div>
		);
	};


	return (
		<div>
			<div className='d-flex justify-content-between my-3 me-3'>
				<div className='align-self-start ms-4 mt-1'>
					<strong>Hello, {userName}</strong>
				</div>
				<div className='align-self-end'>
					{isAdmin ?
						(
							<div>
								<NavHome />
								<button className='btn btn-link no-hover logOut ms-2' onClick={logOut}>
									Log Out
								</button>
							</div>
						) :	(
							<button className='btn btn-link no-hover logOut' onClick={logOut}>
								Log Out
							</button>
						)
					}
				</div>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block mx-auto' width={400} height={70}/>
					</div>
				</div>
			</div>
			<div>
				<h1 className='text-center mt-4'>Conference and Meeting Rooms Booking System</h1>
			</div>
			<br/><br/>
			<div className="container text-center">
				<Link to={`/${brandIdentifier}${brandPages.selectFacility}`}>
					<button className="btn dark-btn btn-lg btn-block mb-3" id="bookingForm">
						<span className='btn-text'>Make New Booking</span>
					</button>
				</Link>
				<br/>
				<Link to={`/${brandIdentifier}${brandPages.userHistory}`}>
					<button className='btn light-btn btn-lg btn-block mb-5'>
						<span className="btn-text" id="exist">View All Bookings</span>
					</button>
				</Link>
			</div>
			<div className='container text-center my-3'>
				<h1>Current Bookings</h1>
			</div>
			<div className='container text-center'>
				{renderBookingsTable()}
			</div>
			{cancelPopupVisible && (
				<CancelPopup
					booking={cancelledBooking}
					onConfirm={() => handleCancelConfirmation(cancelledBooking.id)}
					onCancel={handleCancelPopupClose}
				/>
			)}
			{codePopupVisible && (
				<CodePopup
					codes={codes}
					onCancel={handleCodePopupClose}
				/>
			)}
			{detailsPopupVisible && (
				<DetailsPopup
					booking={selectedBooking}
					layout={layout}
					open={detailsPopupVisible}
					onClose={handleDetailsClose}
				/>
			)}
			{forwardPopupVisible && (
				<ForwardPopup
					booking={selectedBooking}
					open={forwardPopupVisible}
					onClose={handleForwardPopupClose}
				/>
			)}
		</div>
	);
};

export default BookingsDashboard;
