import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@material-ui/core';
import bookingService from '../services/bookings';

const ForwardPopup = ({booking, open, onClose}) => {
	const [email, setEmail] = useState('');

	const forwardEmail = (event) => {
		setEmail(event.target.value);
	};

	// forward qr/otp to email address entered in textfield
	const onSubmit = () => {
		bookingService
			.getCode(booking, true, email)
			.then((response) => {
				onClose();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<div className="forward-popup">
				<DialogTitle>Forward QR/OTP</DialogTitle>
				<DialogContent>
					<TextField
						label="Email Address"
						variant="outlined"
						fullWidth
						value={email}
						type='email'
						onChange={forwardEmail}
					/>
					<div className="d-flex justify-content-center">
						<DialogActions>
							<Button onClick={onClose} id="approve-btn" className="btn">
								Cancel
							</Button>
							<Button onClick={onSubmit} id="deny-btn" className="btn">
								Forward
							</Button>
						</DialogActions>
					</div>
				</DialogContent>
			</div>
		</Dialog>
	);
};

ForwardPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	booking: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ForwardPopup;
