/**
 * The BookingForm component is a function that returns a form with a bunch of
 * inputs and a submit button
 * @returns A form with a submit button, a notification, and a booking calendar.
 */
import React from 'react';
import PropTypes from 'prop-types';
import BookingCalendar from '../BookingCalendar';
import Notification from '../Notification';
import RequestPopup from '../RequestPopup';
import BookingDetailsPopup from '../BookingDetailsPopup';
import NavHome from '../NavHome';
import logo from '../../assets/hospital_logo.svg';

const BookingForm = ({
	name,
	dept,
	contactNumber,
	room,
	venueName,
	venues,
	date,
	startTime,
	endTime,
	purpose,
	remarks,
	equipmentRequest,
	attendees,
	turnovers,
	isAdmin,
	isSubmitting,
	availability,
	futureDate,
	customLayout,
	setDate,
	setStartTime,
	setEndTime,
	setName,
	setDept,
	setRoom,
	setPurpose,
	setRemarks,
	setCustomLayout,
	setAttendees,
	bookings,
	pendingBookings,
	notification,
	handleContactNumberChange,
	handleFormSubmit,
	handleDateSelect,
	handleEquipmentQuantityChange,
	handleLayoutChange,
	error,
	requestPopupVisible,
	logOut,
	timeOptions,
	showBookingDetailsPopup,
	onClose,
	onEventClick,
	selectedBooking,
	selectedLayout,
}) => {
	return (
		<div>
			<div className='d-flex justify-content-left align-items-left ms-4'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img
							src={logo}
							alt="Hospital Logo"
							className='my-4 img-fluid d-block mx-auto'
							width={230}
							height={35}
						/>
					</div>
				</div>
				<div className="flex-grow-1"></div>
				{/* eslint-disable-next-line */}
				<div className='d-flex justify-content-end align-items-center my-3 me-3'>
					<div className="me-3 ms-3">
						<NavHome />
					</div>
					<button
						className='btn btn-link no-hover logOut'
						onClick={logOut}
					>
						Log Out
					</button>
				</div>
			</div>
			<div className="container mb-5 mt-2">
				<h1 className="text-center">Booking Calendar</h1>
				<BookingCalendar
					onDateSelect={handleDateSelect}
					handleEventClick={onEventClick}
					bookings={bookings}
					pendingBookings={pendingBookings}
					turnovers={turnovers}
					isAdmin={isAdmin}
					futureDate={futureDate}
					room={room}
					setRoom={setRoom}
				/>
				{showBookingDetailsPopup && (
					<BookingDetailsPopup
						booking={selectedBooking}
						onClose={onClose}
					/>
				)}
			</div>
			<hr></hr>
			<h1 className="text-center mt-5 mb-5">Booking Form</h1>
			{!isAdmin &&
				(<div className="text-center mx-3">
					{/* eslint-disable*/}
					<p>Booking hours are from <strong>{availability.startTime} to {availability.endTime}</strong> from <strong>Monday to Saturday</strong>.</p>
					<p>You may book up to <strong>{availability.maxBookingDays}</strong> days in advance, before <strong>{futureDate}</strong>.</p>
					<p className='danger-text'><strong>Conference Room 2</strong>: End time at least <strong>30 minutes</strong> before the next booking.</p>
					<p className='danger-text'><strong>Conference Room 1 & 2</strong>: End time at least <strong>1 hour</strong> before the next booking.</p>
				</div>)
			}
			<div className='text-center mb-5 mx-2'>	
				<p>Start and end timing must be in <strong>{availability.TIME_SLOT_LENGTH_IN_MINUTES}-minute</strong> blocks.</p>
			</div>
			{/* eslint-enable */}
			{requestPopupVisible &&
                (<RequestPopup booking={{venueName, startTime, endTime, date, purpose}}
                />
                )}
			<div>
				{notification &&
				<Notification message={notification} isError={error}/>
				}
			</div>
			<div className="container mb-5">
				<form onSubmit={handleFormSubmit}>
					<div className="row mb-4">
						<div className="col-md-3">
							<div className="form-group mb-2">
								Name:
								<input
									type="text"
									value={name}
									placeholder="Name"
									onChange={(e) => setName(e.target.value)}
									required
									className="form-control"
									disabled
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group mb-2">
								Department:
								<input
									type="text"
									value={dept}
									placeholder="Department"
									onChange={(e) => setDept(e.target.value)}
									required
									className="form-control"
									disabled
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group mb-2">
								Contact No.:
								<input
									type="tel"
									value={contactNumber}
									placeholder="Contact No."
									onChange={handleContactNumberChange}
									required
									className="form-control"
									disabled
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								Venue:
								<select
									value={room}
									onChange={(e) => setRoom(e.target.value)}
									required
									className="form-control"
									style={{
										WebkitAppearance: 'none',
										MozAppearance: 'none',
										appearance: 'none',
										paddingLeft: '20px',
										backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23999'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'right center',
										backgroundSize: '15px',
									}}
								>
									<option value="" disabled>Select Venue</option>
									{venues.map((venue) => (
										// eslint-disable-next-line
										<option key={venue.id} value={venue.id}>{venue.name}</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="form-group mb-2">
								Date:
								<input
									type="date"
									value={date}
									onChange={(e) => setDate(e.target.value)}
									placeholder='Date'
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group mb-2">
								Start Time:
								<input
									id="start-time"
									list="times"
									type="time"
									name="start-time"
									value={startTime}
									placeholder={window.innerWidth < 768 ? 'Start Time' : ''}
									onChange={(e) =>
										setStartTime(e.target.value)}
									step="1800"
									required
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group mb-2">
								End Time:
								<input
									id="end-time"
									list="times"
									type="time"
									name="end-time"
									value={endTime}
									placeholder={window.innerWidth < 768 ? 'End Time' : ''}
									onChange={(e) => setEndTime(e.target.value)}
									step="1800"
									required
									className="form-control"
								/>
							</div>
							<datalist id="times">
								{timeOptions.map((option) =>
									<option key={option} value={option} />,
								)}
							</datalist>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								Purpose:
								<input
									type='text'
									value={purpose}
									onChange={(e) => {
										if (e.target.value.length <= 50) {
											setPurpose(e.target.value);
										}
									}}
									required
									className="form-control"
								/>
							</div>
						</div>
					</div>
					<div className="form-group mb-5 mt-3">
						Remarks:
						<textarea
							type='text'
							value={remarks}
							onChange={(e) => {
								if (e.target.value.length <= 100) {
									setRemarks(e.target.value);
								}
							}}
							className="form-control"
							placeholder="Please enter your remarks here. (100 characters max)"
						/>
					</div>
					<div className="mt-5">
						<h5><strong>Audio Visual System Request</strong></h5>
						{venues.map((venue) => {
							if (venue.id === room) {
								// eslint-disable-next-line
								return Object.keys(venue.equipmentList).map((equipment, index) => (
									<div key={index}>
										{index === 0 ? null : <hr/> }
										<label className="mt-3">
											{/* eslint-disable-next-line*/}
											{equipment} (Max: {venue.equipmentList[equipment]})

										</label>
										{/* eslint-disable-next-line*/}
										<div className="d-flex justify-content-start align-items-center mt-2 mb-3">
											<button
												className="btn me-2 minus-btn"
												onClick={(event) =>
													// eslint-disable-next-line
													handleEquipmentQuantityChange(event, equipment)
												}
												name="minus"
												type="button"
											>
												-
											</button>
											<span>
												{/* eslint-disable-next-line*/}
												{equipmentRequest[equipment] > 0 ? equipmentRequest[equipment] : 0}
											</span>
											<button
												className="btn ms-2 plus-btn"
												onClick={(event) =>
													// eslint-disable-next-line
													handleEquipmentQuantityChange(event, equipment)}
												name="plus"
												type="button"
											>
												+
											</button>
										</div>
									</div>
								));
							}
							return null;
						})}
					</div>
					<label className="my-3">For further assistance, please email <a href="mailto:mnh.avsupport@parkwaypantai.com">mnh.avsupport@parkwaypantai.com.</a></label>
					<hr style={{borderWidth: '2px'}} />
					<div className="mt-5">
						<h5>
							<strong>
								Room Arrangement Request
							</strong>
						</h5>
						{/* eslint-disable-next-line */}
						<p><em>If you have custom room arrangement requests, please select 'Custom' and fill in the "Custom Request" text box.</em></p>
						<div className="container mt-5">
							<div className="row">
								<>
									{venues.map((venue) => {
										if (venue.id === room) {
											if (!venue.layout) {
												return <div key="no-layouts">No available layouts</div>;
											}
											// eslint-disable-next-line
											return Object.entries(venue.layout).map(([key, layout]) => {
												const imageSrc = require(`../../assets/images/${key}-${venue.id}.png`);
												return (
													<div key={key} className="text-center mb-2 col-md-6">
														<img src={imageSrc} className="layout-img" alt={layout.setup} style={{width: '500px', height: '280px'}} /><br/>
														<label className="mt-3"><strong>Setup Style: {layout.setup}</strong></label><br/>
														{layout.max !== 0 && (
															<>
																<label className="ms-3">Maximum: {layout.max} pax</label><br/>
															</>
														)}
														<input
															type="radio"
															className="mt-3"
															name={venue}
															value={key}
															style={{marginRight: '0.5rem', width: '1.5rem', height: '1.5rem'}}
															onChange={handleLayoutChange}
															required
														/>
														{/* eslint-disable*/}
														{selectedLayout === key && layout.setup !== 'Custom' && (
															<div className='my-2'>
																<label>Expected number of attendees:</label>
																<input
																	type='number'
																	value={attendees}
																	onChange={(e) => {
																		const value = parseInt(e.target.value);

																		if (value > 0 && value <= layout.max) {
																			setAttendees(e.target.value)
																		}
																	}}
																	className="form-control"
																/>
															</div>
														)}
														{selectedLayout === key && layout.setup === 'Custom' && (
															<div className='row'>
																<div className="form-group mb-4 mt-3">
																	Custom Request:
																	<textarea
																		type='text'
																		value={customLayout}
																		onChange={(e) => {
																			if (e.target.value.length <= 100) {
																				setCustomLayout(e.target.value);
																			}
																		}}
																		className="form-control"
																		placeholder="Please enter your custom request here. (100 characters max)"
																	/>
																</div>
															</div>
														)}
														{/* eslint-enable */}
													</div>
												);
											});
										}
										return null;
									})}
								</>
							</div>
						</div>
					</div>
					<label className="my-4">For further assistance, please email <a href="mailto:chunjing.choong@mountelizabeth.com.sg">chunjing.choong@mountelizabeth.com.sg.</a></label>
					<div>
						{notification &&
							<Notification message={notification} isError={error}/>
						}
					</div>
					<div className="container text-center my-4">
						<button
							id="form-submit"
							className="btn light-btn btn-sm btn-block"
							type="submit"
							disabled={isSubmitting}
						>
							<span className="btn-text">Submit Request</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

BookingForm.propTypes = {
	name: PropTypes.string,
	dept: PropTypes.string,
	contactNumber: PropTypes.string,
	room: PropTypes.string,
	venueName: PropTypes.string,
	venues: PropTypes.array,
	date: PropTypes.string,
	startTime: PropTypes.string,
	endTime: PropTypes.string,
	purpose: PropTypes.string,
	equipmentRequest: PropTypes.object,
	attendees: PropTypes.string,
	turnovers: PropTypes.array,
	remarks: PropTypes.string,
	isAdmin: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	availability: PropTypes.object,
	futureDate: PropTypes.string,
	customLayout: PropTypes.string,
	setDate: PropTypes.func,
	setStartTime: PropTypes.func,
	setEndTime: PropTypes.func,
	setName: PropTypes.func,
	setDept: PropTypes.func,
	setRoom: PropTypes.func,
	setPurpose: PropTypes.func,
	setRemarks: PropTypes.func,
	setCustomLayout: PropTypes.func,
	setAttendees: PropTypes.func,
	bookings: PropTypes.array,
	pendingBookings: PropTypes.array,
	notification: PropTypes.string,
	handleContactNumberChange: PropTypes.func,
	handleFormSubmit: PropTypes.func,
	handleDateSelect: PropTypes.func,
	handleEquipmentQuantityChange: PropTypes.func,
	handleLayoutChange: PropTypes.func,
	error: PropTypes.bool,
	requestPopupVisible: PropTypes.bool,
	logOut: PropTypes.func,
	timeOptions: PropTypes.array,
	showBookingDetailsPopup: PropTypes.bool,
	onClose: PropTypes.func,
	onEventClick: PropTypes.func,
	selectedBooking: PropTypes.object,
	selectedLayout: PropTypes.string,
};

export default BookingForm;
