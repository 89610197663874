/**
 * The AddEditUser function is a React component that allows for adding or editing user information and
 * includes form validation and a delete user option.
 * @returns The `AddEditUser` component is being returned.
 */
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import UserService from '../../services/users';
import UserDeletePopup from '../UserDeletePopup';
import Notification from '../Notification';
import logo from '../../assets/hospital_logo.svg';

const AddEditUser = () => {
	const [name, setName] = useState('');
	const [designation, setDesignation] = useState('');
	const [dept, setDept] = useState('');
	const [email, setEmail] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [isEdit, setIsEdit] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [isError, setIsError] = useState(false);
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;
	const navigate = useNavigate();
	const location = useLocation();

	const user = location.state?.user;

	useEffect(() => {
		if (user) {
			// if user is passed as a prop, set the form fields to the user's details
			// and show edit form
			setIsEdit(true);
			const {name, designation, dept, email, contactNo} = user;
			setName(name);
			setDesignation(designation);
			setDept(dept);
			setEmail(email);
			setContactNumber(contactNo);
		}
	}, [user]);


	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	// only allow 8 digits in contact number field
	const handleContactNumberChange = (event) => {
		const inputValue = event.target.value;
		const regex = /^\d{0,8}$/; // Only allow up to 8 digits
		if (regex.test(inputValue)) {
			setContactNumber(inputValue);
		}
	};

	// for creating a new user
	const handleForm = async (event) => {
		event.preventDefault();

		const newUser = {
			name: name,
			designation: designation,
			dept: dept,
			email: email,
			contactNo: contactNumber,
		};

		// check if contact number has 8 digits
		if (contactNumber.length !== 8) {
			setIsError(true);
			setShowNotification(true);
			setNotificationMessage('Error: Contact number must have exactly 8 digits.');
			setTimeout(() => {
				setShowNotification(false);
				setIsError(false);
				setNotificationMessage('');
			}, 3000);
			return;
		}

		// check if email is valid
		const emailRegex = /^\S+@\S+\.\S+$/;
		const isValidEmail = emailRegex.test(email);

		if (!isValidEmail) {
			setIsError(true);
			setShowNotification(true);
			setNotificationMessage('Error: Please enter a valid email address.');
			setTimeout(() => {
				setShowNotification(false);
				setIsError(false);
				setNotificationMessage('');
			}, 3000);
			return;
		}

		// save new user to database
		UserService.create(newUser)
			.then((response) => {
				navigate(`/${brandIdentifier}${brandPages.adminUserList}`);
			})
			.catch((error) => {
				console.log(error);
				setIsError(true);
				setShowNotification(true);
				setNotificationMessage(`Error: User could not be added, 
  					check that email is unique.`);
				setTimeout(() => {
					setShowNotification(false);
					setIsError(false);
					setNotificationMessage('');
				}
				, 3000);
			});
	};

	// for updating an existing user
	const handleUpdate = (event) => {
		event.preventDefault();

		const updatedUser = {
			name: name,
			designation: designation,
			dept: dept,
			email: email,
			contactNo: contactNumber,
		};

		// save updated user to database
		UserService.update(user.id, updatedUser)
			.then((response) => {
				navigate(`/${brandIdentifier}${brandPages.adminUserList}`);
			})
			.catch((error) => {
				console.log(error);
				setIsError(true);
				setShowNotification(true);
				setNotificationMessage(`Error: Failed to update the user. The email provided is already in use. 
					If this email was used by a deleted user, consider creating a new user with the same email.`,
				);
			});
	};

	return (
		<div>
			<div className='d-flex justify-content-end my-3 me-3'>
				<button
					className='btn btn-link no-hover cancel-link'
					onClick={() => navigate(`/${brandIdentifier}${brandPages.adminUserList}`)}
					style={{textDecoration: 'none'}}
				>
					Cancel
				</button>
				<button
					className='btn btn-link no-hover logOut'
					onClick={logOut}
				>
					Log Out
				</button>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img
							src={logo}
							alt="Hospital Logo"
							className='my-4 px-3 img-fluid d-block mx-auto'
							width={400} height={70}
						/>
					</div>
				</div>
			</div>
			<h1 className="text-center my-5">{isEdit ? 'Edit User' : 'Add User'}</h1>
			<div className="container mb-5">
				<form onSubmit={isEdit ? handleUpdate : handleForm}>
					<div className="row mb-4">
						<div className="col-md-4">
							<div className="form-group mb-2">
								<input
									type="text"
									value={name}
									placeholder="Name"
									onChange={(e) => setName(e.target.value)}
									required
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group mb-2">
								<input
									type="text"
									value={designation}
									placeholder="Designation"
									onChange={(e) => setDesignation(e.target.value)}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group mb-2">
								<input
									name="depts"
									value={dept}
									onChange={(e) => setDept(e.target.value)}
									placeholder='Department'
									required
									className="form-control"
									// style={{
									// 	WebkitAppearance: 'none',
									// 	MozAppearance: 'none',
									// 	appearance: 'none',
									// 	paddingLeft: '20px',
									// 	backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23999'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")`,
									// 	backgroundRepeat: 'no-repeat',
									// 	backgroundPosition: 'right center',
									// 	backgroundSize: '15px',
									// }}
								>
									{/* <option value="">Department</option>
									{deptOptions.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))} */}
								</input>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="form-group mb-2">
								<input
									type="email"
									value={email}
									placeholder="Email Address"
									onChange={(e) => setEmail(e.target.value)}
									required
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group mb-2">
								<input
									type="tel"
									value={contactNumber}
									placeholder="Contact No."
									onChange={handleContactNumberChange}
									required
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-md-4">
							<button
								type="submit"
								className="btn approve-btn btn-block update-add-user-btn"
								style={{width: '100%'}}
							>
								{isEdit ? 'Update User' : 'Add User'}
							</button>
						</div>
					</div>
					{isEdit && (
						<div className="row">
							<div className="col-md-4"></div>
							<div className="col-md-4">
								<button
									type="button"
									className="btn deny-btn btn-block mt-4"
									style={{width: '100%'}}
									// Show the confirmation popup when the button is clicked
									onClick={() => setShowDeletePopup(true)}
								>
									Delete User
								</button>
							</div>
							<div className="col-md-4"></div>
						</div>
					)}
				</form>
				{showDeletePopup && ( // Render the delete popup
					<UserDeletePopup
						user={user}
						onConfirm={() => {
							UserService.remove(user.id)
								.then((response) => {
									navigate(`/${brandIdentifier}${brandPages.adminUserList}`);
								})
								.catch((error) => {
									console.log(error);
									setNotificationMessage(
										'An error occurred while deleting the user. Please try again.',
									);
								});
							// Close the delete popup after the user is deleted
							setShowDeletePopup(false);
						}}
						// Close the delete popup if the admin cancels
						onCancel={() => setShowDeletePopup(false)}
					/>
				)}
				{showNotification && (
					<Notification
						message={notificationMessage}
						isError={isError}
					/>
				)}
			</div>
		</div>
	);
};

export default AddEditUser;
