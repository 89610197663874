/**
 * It's a popup that allows the user to enter a reason for denying a request
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
} from '@material-ui/core';

const DenyPopup = ({open, onClose, onSubmit}) => {
	const [reason, setReason] = useState('');

	const handleReasonChange = (event) => {
		setReason(event.target.value);
	};

	const handleSubmit = () => {
		onSubmit(reason);
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<div className='deny-popup'>
				<DialogTitle>Reason For Cancellation</DialogTitle>
				<DialogContent>
					<TextField
						label="Reason"
						variant="outlined"
						fullWidth
						multiline
						minRows={4}
						value={reason}
						onChange={handleReasonChange}
					/>
				</DialogContent>
				<div className='d-flex justify-content-center'>
					<DialogActions>
						<Button onClick={onClose} id="approve-btn" className='btn'>
							Cancel
						</Button>
						<Button onClick={handleSubmit} id="deny-btn" className='btn'>
							Deny
						</Button>
					</DialogActions>
				</div>
			</div>
		</Dialog>
	);
};

DenyPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default DenyPopup;
