/**
 * It fetches all the bookings and pending bookings from the server and displays them in a table
 * @returns A table of all the bookings.
 */
import React, {useEffect, useState} from 'react';
import bookingsService from '../../services/bookings';
import pendingBookingsService from '../../services/pendingBookings';
import logo from '../../assets/hospital_logo.svg';
import NavHome from '../NavHome';
import DetailsPopup from '../DetailsPopup';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import {CSVLink} from 'react-csv';

const BookingLogs = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [bookings, setBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [allBookings, setAllBookings] = useState([]);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
	const [layout, setLayout] = useState('');
	const [hoveredEmail, setHoveredEmail] = useState(null);
	const [hoveredContactNo, setHoveredContactNo] = useState(null);
	const [csvData, setCSVData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [dateSortOrder, setDateSortOrder] = useState('latest');


	useEffect(() => {
		// Fetch bookings data from server and update state
		bookingsService
			.getAll()
			.then((initialBookings) => {
				setBookings(initialBookings);
			});
		pendingBookingsService
			.getAll()
			.then((initialPendingBookings) => {
				setPendingBookings(initialPendingBookings);
			},
			);
	}, []);

	useEffect(() => {
		// combine bookings and pending bookings
		const combinedBookings = [...bookings, ...pendingBookings];
		// filter out bookings that are more than 30 days old
		const filteredNinetyDaysBookings = combinedBookings.filter((booking) => {
			const bookingDate = new Date(booking.date);
			const today = new Date();
			const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 365));
			return bookingDate > ninetyDaysAgo;
		});
		setAllBookings(filteredNinetyDaysBookings);
		if (allBookings.length > 0) {
			setIsLoading(false);
		}
	}, [bookings, pendingBookings]);

	// set status style based on status type
	const getStatusStyle = (status) => {
		switch (status) {
		case 'Approved':
			return {color: '#2A9D8F', fontWeight: 'bold'};
		case 'Cancelled':
		case 'Denied':
			return {color: '#E76F51', fontWeight: 'bold'};
		default:
			return {};
		}
	};

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const handleCSVDownload = async (done) => {
		// Fetch bookings data from server
		const initialBookings = await bookingsService.getAll('true');

		// Fetch pending bookings data from server
		const initialPendingBookings = await pendingBookingsService.getAll('true');

		// Combine bookings and pending bookings
		const allData = [...initialBookings, ...initialPendingBookings];

		// Store combined data in csvData
		setCSVData(allData);
		setIsDataLoaded(true);
	};

	// open the details popup
	const handleDetailsPopup = (booking) => {
		setSelectedBooking(booking);
		// Check if the necessary properties exist before accessing them
		const layout = booking.venue?.layout?.[booking.layout]?.setup || 'NIL';
		setLayout(layout !== undefined ? layout : 'NIL');
		setDetailsPopupVisible(true);
	};

	// close the details popup
	const handleDetailsClose = () => {
		setSelectedBooking(null);
		setDetailsPopupVisible(false);

		// Fetch pending bookings/ bookings data from server and update state to reflect any changes
		pendingBookingsService
			.getAll()
			.then((initialPendingBookings) => {
				setPendingBookings(initialPendingBookings);
			});

		bookingsService
			.getAll()
			.then((initialBookings) => {
				setBookings(initialBookings);
			});
	};

	const maskEmail = (email) => {
		const [username, domain] = email.split('@');
		const maskedUsername = username.slice(0, Math.floor(username.length / 2)) + '*'.repeat(username.length - Math.floor(username.length / 2));
		return maskedUsername + '@' + domain;
	};

	const maskContactNo = (contactNo) => {
		return String(contactNo).slice(0, String(contactNo).length - 4) + '*'.repeat(4);
	};

	const handleEmailHover = (id) => {
		setHoveredEmail(id);
	};

	const handleContactNoHover = (id) => {
		setHoveredContactNo(id);
	};

	const handleMouseLeave = () => {
		setHoveredEmail(null);
		setHoveredContactNo(null);
	};

	// To display pagination of bookings
	const itemsPerPage = 15;
	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	// sort bookings by date based on dateSortOrder
	const sortedBookings = allBookings.slice().sort((a, b) => {
		const dateA = new Date(a.date);
		const dateB = new Date(b.date);
		if (dateSortOrder === 'latest') {
			return dateB - dateA; // Sort from latest to earliest
		} else {
			return dateA - dateB; // Sort from earliest to latest
		}
	});

	const toggleDateSortOrder = () => {
		setDateSortOrder(dateSortOrder === 'latest' ? 'earliest' : 'latest');
	};

	// bookings to display on current page
	const bookingsToDisplay = sortedBookings.slice(startIndex, endIndex);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const totalPages = Math.ceil(sortedBookings.length / itemsPerPage);
	const isMobile = window.innerWidth <= 768;
	let visiblePages = 20; // default number of visible pages
	if (isMobile) {
		visiblePages = 5; // number of visible pages on mobile
	}

	// Calculate the range of visible page numbers
	const startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
	const endPage = Math.min(startPage + visiblePages - 1, totalPages);

	// Generate the array of visible page numbers
	// eslint-disable-next-line
	const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

	return (
		<div>
			<div className='d-flex justify-content-left align-items-left ms-4'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 img-fluid d-block mx-auto' width={230} height={35}/>
					</div>
				</div>
				<div className="flex-grow-1"></div>
				<div className='d-flex justify-content-end align-items-center my-3 me-3'>
					<div className="me-3 ms-3">
						<NavHome />
					</div>
					<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
				</div>
			</div>
			<h1 className="text-center mb-4">Booking Logs</h1>
			<p className='text-center'>Past bookings are displayed up to 1 year ago.</p>
			{isLoading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner className="mt-5" animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			) : (
				<>
					<div className="d-flex justify-content-center justify-content-md-end me-md-5 pe-md-5">
						{isDataLoaded ? (
							<CSVLink
								data={csvData}
								filename="booking-logs.csv"
								className="btn download-csv-btn mb-4 mt-1"
							>
								Ready To Download
							</CSVLink>
						) : (
							<button
								className="btn load-csv-btn mb-4 mt-1"
								onClick={handleCSVDownload}
							>
								Load Data For Download
							</button>
						)}
					</div>
					<div className="table-responsive">
						<table>
							<thead>
								<tr>
									<th>Name</th>
									<th>Department</th>
									<th>Email</th>
									<th>Contact Number</th>
									<th>Venue</th>
									<th onClick={toggleDateSortOrder} style={{cursor: 'pointer'}}>
										Date
										<span style={{fontSize: '12px', marginLeft: '2px'}}>
											{dateSortOrder === 'latest' ? ' ▼' : ' ▲'}
										</span>
									</th>
									<th>Start Time</th>
									<th>End Time</th>
									<th>Details</th>
									<th>Status</th>
									<th>Approved By</th>
									<th>Checked In</th>
									<th>OTP</th>
								</tr>
							</thead>
							<tbody>
								{bookingsToDisplay
									.map((booking) => {
										/* Checking if the booking is pending or approved. */
										// eslint-disable-next-line
										const isPending = pendingBookings.some((pendingBooking) =>
											pendingBooking.id === booking.id);
										const isDenied = booking.status === 'Denied';
										const isCancelled = booking.status === 'Cancelled';
										const status = isCancelled ? 'Cancelled' : isDenied ? 'Denied' : isPending ? 'Pending' : 'Approved';
										const statusStyle = getStatusStyle(status);

										return (
											<tr key={booking.id}>
												<td>{booking.name}</td>
												<td>{booking.dept}</td>
												<td style={{maxWidth: '700px'}}>
													<span
														className="hoverable-value"
														onMouseEnter={
															// eslint-disable-next-line
															() => handleEmailHover(booking.id)
														}
														onMouseLeave={handleMouseLeave}
													>
														{/* eslint-disable-next-line */}
														{hoveredEmail === booking.id ? booking.user.email : maskEmail(booking.user.email)}
													</span>
												</td>
												<td>
													<span
														className="hoverable-value"
														onMouseEnter={
															// eslint-disable-next-line
															() => handleContactNoHover(booking.id)
														}
														onMouseLeave={handleMouseLeave}
													>
														{/* eslint-disable-next-line */}
														{hoveredContactNo === booking.id ? booking.user.contactNo : maskContactNo(booking.user.contactNo)}
													</span>
												</td>
												<td>{booking.venue.name}</td>
												<td>{new Date(booking.date).toLocaleDateString('en-GB')}</td>
												<td>{booking.startTime}</td>
												<td>{booking.endTime}</td>
												<td>
													<button
														className='view-btn'
														onClick={
															// eslint-disable-next-line
															() => handleDetailsPopup(booking)
														}
													>
														View
													</button>
												</td>
												<td style={statusStyle}>{status}</td>
												<td>{booking.approvedBy ? booking.approvedBy : '-'}</td>
												<td>{booking.checkedIn ? '✓' : '-'}</td>
												<td>{booking.otp ? booking.otp : '-'}</td>
											</tr>
										);
									})
								}
							</tbody>
						</table>
					</div>
					<Pagination className="my-pagination justify-content-center mt-4">
						{/* Previous page button */}
						<Pagination.Prev
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
						/>
						{/* Visible page numbers */}
						{pageNumbers.map((pageNumber) => (
							<Pagination.Item
								key={pageNumber}
								active={pageNumber === currentPage}
								onClick={() => handlePageChange(pageNumber)}
							>
								{pageNumber}
							</Pagination.Item>
						))}
						{/* Next page button */}
						<Pagination.Next
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={
								// eslint-disable-next-line
								currentPage === Math.ceil(sortedBookings.length / itemsPerPage)
							}
						/>
					</Pagination>
					{detailsPopupVisible && (
						<DetailsPopup
							booking={selectedBooking}
							layout={layout}
							open={detailsPopupVisible}
							onClose={handleDetailsClose}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default BookingLogs;
