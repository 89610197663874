/* This is a React component that renders the pending bookings table. */
import React from 'react';
import PropTypes from 'prop-types';
import DenyPopup from '../DenyPopup';
import DetailsPopup from '../DetailsPopup';
import logo from '../../assets/hospital_logo.svg';
import NavHome from '../NavHome';

const PendingBookingsJsx = ({
	pendingBookings,
	layout,
	handleApprove,
	handleDeny,
	handleDenyCancel,
	handleDenySubmit,
	handleDetailsPopup,
	handleDetailsClose,
	denyPopupVisible,
	detailsPopupVisible,
	selectedBooking,
	logOut,
}) => {
	return (
		<div>
			<div className='d-flex justify-content-end my-3 me-3'>
				<div className="me-3 mt-1">
					<NavHome />
				</div>
				<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block mx-auto' width={400} height={70}/>
					</div>
				</div>
			</div>
			<h1 className="text-center my-4">Pending Bookings</h1>
			{pendingBookings.length === 0 ? (
				<p className="text-center">No new pending bookings.</p>
			) : (
				<div className="table-responsive">
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Department</th>
								<th>Email</th>
								<th>Venue</th>
								<th>Date</th>
								<th>Start Time</th>
								<th>End Time</th>
								<th>Contact Number</th>
								<th>Details</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{/* eslint-disable-next-line*/}
							{pendingBookings.filter((booking) => !booking.status).map((booking) => (
								<tr key={booking.id}>
									<td>{booking.name}</td>
									<td>{booking.dept}</td>
									<td>{booking.user.email}</td>
									<td>{booking.venue.name}</td>
									<td>{new Date(booking.date).toLocaleDateString('en-GB')}</td>
									<td>{booking.startTime}</td>
									<td>{booking.endTime}</td>
									<td>{booking.contactNumber}</td>
									<td>
										<button
											className='view-btn'
											onClick={() => handleDetailsPopup(booking)}
										>
											View
										</button>
									</td>
									<td>
										<div className="d-flex justify-content-center">
											<button className="btn approve-btn me-1" onClick={() => handleApprove(booking)}>Approve</button>
											<button className="btn deny-btn ms-1" onClick={() => handleDeny(booking)}>Deny</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			{denyPopupVisible && (
				<DenyPopup
					booking={selectedBooking}
					open={denyPopupVisible}
					onSubmit={handleDenySubmit}
					onClose={handleDenyCancel}
				/>
			)}
			{detailsPopupVisible && (
				<DetailsPopup
					booking={selectedBooking}
					layout={layout}
					open={detailsPopupVisible}
					onClose={handleDetailsClose}
				/>
			)}
		</div>
	);
};

PendingBookingsJsx.propTypes = {
	pendingBookings: PropTypes.array.isRequired,
	layout: PropTypes.string.isRequired,
	handleApprove: PropTypes.func.isRequired,
	handleDeny: PropTypes.func.isRequired,
	handleDenyCancel: PropTypes.func.isRequired,
	handleDenySubmit: PropTypes.func.isRequired,
	handleDetailsPopup: PropTypes.func.isRequired,
	handleDetailsClose: PropTypes.func.isRequired,
	denyPopupVisible: PropTypes.bool.isRequired,
	detailsPopupVisible: PropTypes.bool.isRequired,
	selectedBooking: PropTypes.object,
	logOut: PropTypes.func.isRequired,
};

export default PendingBookingsJsx;
