/**
 * This is a React component that displays a popup with a QR code and a TOTP code, and a countdown
 * timer for the TOTP code validity.
 * @returns The `CodePopup` component is being returned.
 */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const CodePopup = ({codes, onCancel}) => {
	const [qrCodeImage, setQrCodeImage] = useState(null);
	const [countdown, setCountdown] = useState(15); // countdown for code validity, 15 seconds

	useEffect(() => {
		const img = new Image();
		img.src = codes.qrCode;
		img.onload = () => setQrCodeImage(img);

		const timer = setInterval(() => {
			if (countdown > 0) {
				setCountdown(countdown - 1);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [codes.qrCode, countdown]);

	// useEffect(() => {
	//     if (countdown === 0) {
	//         onCancel();
	//     }
	// }, [countdown, onCancel]);

	// const formattedCountdown = countdown.toString().padStart(2, '0');
	// const timerClassName = countdown <= 5 ? 'text-danger' : '';

	return (
		<div className="code-popup">
			<div className='code-popup-content code-popup-resize text-center'>
				<h2 style={{color: '#2a9d8f'}}>Scan the QR code</h2>
				<h5 className='mb-3'>Hold your phone up to the scanner on the tablet</h5>
				{qrCodeImage && <img src={qrCodeImage.src} alt="QR code" />}
				<h3 className='my-3' style={{color: '#E76F51'}}>OR</h3>
				<h2 className='mt-3'>Enter OTP:</h2>
				<h2 className='text-center totp-text'>{codes.otp}</h2>
				{/* <div className={`text-center ${timerClassName} mb-3`}><strong>{formattedCountdown}</strong> seconds left</div> */}
				<button className="btn dark-btn btn-lg btn-block text-white mt-2" onClick={onCancel}>Close</button>
			</div>
		</div>
	);
};

CodePopup.propTypes = {
	codes: PropTypes.object.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default CodePopup;
