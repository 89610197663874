/**
 * It renders a table of bookings, and allows the user to cancel a booking
 */
import React, {useState, useEffect} from 'react';
import logo from '../../assets/hospital_logo.svg';
import bookingsService from '../../services/bookings';
import pendingBookingsService from '../../services/pendingBookings';
import usersService from '../../services/users';
import CancelPopup from '../CancelPopup';
import DetailsPopup from '../DetailsPopup';
import NavHome from '../NavHome';
import Pagination from 'react-bootstrap/Pagination';

const BookingHistory = () => {
	const tableHeaders = ['Venue', 'Date', 'Start Time', 'End Time', 'Details', 'Status', 'Action'];
	const [currentBookings, setCurrentBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [cancelPopupVisible, setCancelPopupVisible] = useState(false);
	const [cancelledBooking, setCancelledBooking] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
	const [layout, setLayout] = useState('');

	useEffect(() => {
		// Fetch bookings data from server and update state
		const userId = window.localStorage.getItem('userId');
		usersService
			.getBookings(userId)
			.then((user) => {
				setPendingBookings(user.pendingBookings);
				const allBookings = [...user.bookings, ...user.pendingBookings];
				setCurrentBookings(allBookings);
			});
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const cancelBooking = (booking) => {
		setCancelledBooking(booking);
		setCancelPopupVisible(true);
	};

	// This function is called when the user clicks the "Cancel" button in the
	// cancel popup. It updates the status of the booking to "Cancelled" and
	// closes the popup.
	const handleCancelConfirmation = () => {
		const bookingId = cancelledBooking.id;
		const bookingToRemove = currentBookings.find(
			(booking) => booking.id === bookingId,
		);

		if (
			bookingToRemove &&
            pendingBookings.some((booking) => booking.id === bookingToRemove.id)
		) {
			pendingBookingsService
				.update(bookingId, {status: 'Cancelled', cancelledReason: 'User Cancelled'})
				.then(() => {
					// update state to reflect the change
					const userId = window.localStorage.getItem('userId');
					usersService
						.getBookings(userId)
						.then((user) => {
							setPendingBookings(user.pendingBookings);
							// eslint-disable-next-line max-len
							const allBookings = [...user.bookings, ...user.pendingBookings];
							setCurrentBookings(allBookings);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			bookingsService
				.update(bookingId, {status: 'Cancelled', cancelledReason: 'User Cancelled'})
				.then(() => {
					// update state to reflect the change
					const userId = window.localStorage.getItem('userId');
					usersService
						.getBookings(userId)
						.then((user) => {
							setPendingBookings(user.pendingBookings);
							// eslint-disable-next-line max-len
							const allBookings = [...user.bookings, ...user.pendingBookings];
							setCurrentBookings(allBookings);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}

		setCancelledBooking(null);
		setCancelPopupVisible(false);
	};

	const getStatusStyle = (status) => {
		switch (status) {
		case 'Approved':
			return {color: '#2A9D8F', fontWeight: 'bold'};
		case 'Cancelled':
		case 'Denied':
			return {color: '#E76F51', fontWeight: 'bold'};
		default:
			return {};
		}
	};

	// To display pagination of bookings
	const itemsPerPage = 12;
	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	// sort bookings by date from latest to oldest
	const sortedBookings = currentBookings
		.sort((a, b) => new Date(b.date) - new Date(a.date));

	// slice bookings for pagination
	const bookingsToDisplay = sortedBookings.slice(startIndex, endIndex);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const totalPages = Math.ceil(sortedBookings.length / itemsPerPage);
	const isMobile = window.innerWidth <= 768;
	let visiblePages = 20; // default number of visible pages
	if (isMobile) {
		visiblePages = 5; // number of visible pages on mobile
	}

	// Calculate the range of visible page numbers
	const startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
	const endPage = Math.min(startPage + visiblePages - 1, totalPages);

	// Generate the array of visible page numbers
	// eslint-disable-next-line
	const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

	const renderBookingsTable = () => {
		if (currentBookings.length === 0) {
			return <p className="text-center">No current bookings found.</p>;
		}

		return (
			<div>
				<div className="table-responsive">
					<table>
						<thead>
							<tr>
								{tableHeaders.map((header) => (
									<th key={header}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{bookingsToDisplay
								.map((booking) => {
									// Checking if the booking is pending, approved, denied or cancelled.
									// eslint-disable-next-line
									const isPending = pendingBookings.some((pendingBooking) =>
										pendingBooking.id === booking.id);
									const isDenied = booking.status === 'Denied';
									const isCancelled = booking.status === 'Cancelled';
									const status = isCancelled ? 'Cancelled' : isDenied ? 'Denied' : isPending ? 'Pending' : 'Approved';
									const statusStyle = getStatusStyle(status);

									// Check if the booking date & time has already passed
									const now = new Date();
									const dateString = `${booking.date}T${booking.endTime}:00`;
									const bookingDateTime = new Date(dateString);
									const isBookingInFuture = bookingDateTime >= now;

									// Parse the date string and format it in dd-mm-yyyy format
									const date = new Date(booking.date);
									const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});

									/* Checking if the booking is pending or approved. If it is, it will
									display a button that allows the user to cancel the booking.
									Otherwise, it will display a dash. */
									const cancelButton = status === 'Pending' || status === 'Approved' ? (
										<button
											className="btn border-dark"
											onClick={() => cancelBooking(booking)}
										>
											CANCEL BOOKING
										</button>
									) : (
										'-'
									);

									/* For the actions column, it is checking if the booking date
									& time has already passed. If it has, it will display "Expired"
									in the table. Otherwise, it will display a button that allows
									the user to cancel the booking. */
									return (
										<tr key={booking.id}>
											<td>{booking.venue.name}</td>
											<td>{formattedDate}</td>
											<td>{booking.startTime}</td>
											<td>{booking.endTime}</td>
											<td>
												<button
													className='view-btn'
													onClick={
														() => handleDetailsPopup(booking)
													}
												>
													View
												</button>
											</td>
											<td style={statusStyle}>{status}</td>
											{isBookingInFuture ? (
												<td>
													{cancelButton}
												</td>
											) : (
												<td>Expired</td>
											)}
										</tr>
									);
								})
							}
						</tbody>
					</table>
				</div>
				<Pagination className="my-pagination justify-content-center mt-4">
					{/* Previous page button */}
					<Pagination.Prev
						onClick={() => handlePageChange(currentPage - 1)}
						disabled={currentPage === 1}
					/>
					{/* Visible page numbers */}
					{pageNumbers.map((pageNumber) => (
						<Pagination.Item
							key={pageNumber}
							active={pageNumber === currentPage}
							onClick={() => handlePageChange(pageNumber)}
						>
							{pageNumber}
						</Pagination.Item>
					))}
					{/* Next page button */}
					<Pagination.Next
						onClick={() => handlePageChange(currentPage + 1)}
						disabled={
							currentPage === Math.ceil(sortedBookings.length/itemsPerPage)
						}
					/>
				</Pagination>
			</div>
		);
	};

	const handleCancelPopupClose = () => {
		setCancelledBooking(null);
		setCancelPopupVisible(false);
	};

	// open the details popup
	const handleDetailsPopup = (booking) => {
		setSelectedBooking(booking);
		// Check if the necessary properties exist before accessing them
		const layout = booking.venue?.layout?.[booking.layout]?.setup || 'NIL';
		setLayout(layout !== undefined ? layout : 'NIL');
		setDetailsPopupVisible(true);
	};

	// close the details popup
	const handleDetailsClose = () => {
		setSelectedBooking(null);
		setDetailsPopupVisible(false);
	};

	return (
		<div>
			<div className='d-flex justify-content-end align-items-center my-3 me-3'>
				<div className="me-3">
					<NavHome />
				</div>
				<button
					className='btn btn-link no-hover logOut'
					onClick={logOut}
				>
					Log Out
				</button>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block mx-auto' width={400} height={70}/>
					</div>
				</div>
			</div>
			<div>
				<h1 className='text-center my-4'>Booking History</h1>
			</div>
			<div>
				{renderBookingsTable()}
				{cancelPopupVisible && (
					<CancelPopup
						booking={cancelledBooking}
						onConfirm={() => handleCancelConfirmation(cancelledBooking.id)}
						onCancel={handleCancelPopupClose}
					/>
				)}
				{detailsPopupVisible && (
					<DetailsPopup
						booking={selectedBooking}
						layout={layout}
						open={detailsPopupVisible}
						onClose={handleDetailsClose}
					/>
				)}
			</div>
		</div>
	);
};

export default BookingHistory;
