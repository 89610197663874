/**
 * It renders a dropdown to select the view, a dropdown to select the venue, and a
 * FullCalendar component that displays the bookings for the selected venue room
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import venuesService from '../services/venues';
import availabilityService from '../services/availability';
import Spinner from 'react-bootstrap/Spinner';

const BookingCalendar = ({
	bookings,
	pendingBookings,
	turnovers,
	onDateSelect,
	isAdmin,
	handleEventClick,
	futureDate,
	room,
	setRoom,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [view, setView] = useState('');
	const [key, setKey] = useState(0);
	const [venues, setVenues] = useState([]);
	const [events, setEvents] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState('');
	// eslint-disable-next-line
	const [selectedDate, setSelectedDate] = useState(null);
	const [businessHours, setBusinessHours] = useState({});
	const [publicHolidays, setPublicHolidays] = useState([]);

	useEffect(() => {
		// Fetch venues data from server and update state
		venuesService
			.getAll()
			.then((initialVenues) => {
				setVenues(initialVenues);

				// If the room prop is passed from parent component, set the selected room to the room prop
				if (room) {
					setSelectedRoom(room);
				} else {
					setSelectedRoom(initialVenues[0].id);
				}
			});
	}, [room]);

	useEffect(() => {
		// Retrieve facility available days and hours to show on the calendar
		availabilityService
			.getAvailability()
			.then((response) => {
				const data = response;
				setBusinessHours({
					daysOfWeek: data.daysOfWeek,
					startTime: data.startTime,
					endTime: data.endTime,
				});
				// create blocked holidays for the calendar
				const blockedHolidays = {
					events: data.holidaysToDisplay.map((holiday) => ({
						title: holiday.summary,
						start: new Date(`${holiday.startDate}T00:00:00`), // 12:00 am on the start date
						end: new Date(`${holiday.endDate}T00:00:00`), // 12:00 am on the end date
						extendedProps: {
							isHoliday: true,
						},
					})),
				};
				setPublicHolidays(blockedHolidays);
			});
	}, []);

	useEffect(() => {
		// Creating an array of objects that will be used to render the events on the calendar.
		const events = [
			...(bookings?.length ? // if there are bookings
				bookings.filter((booking) => booking.venue.id === selectedRoom) // filter the bookings based on the selected room
					.map((booking) => ({ // map the bookings to the format required by the calendar
						start: new Date(`${booking.date}T${booking.startTime}`),
						end: new Date(`${booking.date}T${booking.endTime}`),
						extendedProps: {
							id: booking.id,
							name: booking.name,
							dept: booking.dept,
							contactNumber: booking.contactNumber,
							venue: booking.venue.name,
							purpose: booking.purpose,
							pending: false,
						},
						color: null,
					})) : [] // if there are no bookings, return an empty array
			),

			...(pendingBookings?.length ? // if there are pending bookings
				pendingBookings.filter((pendingBooking) =>
					pendingBooking.venue.id === selectedRoom) // filter the pendingbookings based on the selected room
					.map((pendingBooking) => ({ // map the pending bookings to the format required by the calendar
						start: new Date(`${pendingBooking.date}T${pendingBooking.startTime}`),
						end: new Date(`${pendingBooking.date}T${pendingBooking.endTime}`),
						extendedProps: {
							id: pendingBooking.id,
							name: pendingBooking.name,
							dept: pendingBooking.dept,
							contactNumber: pendingBooking.contactNumber,
							venue: pendingBooking.venue,
							purpose: pendingBooking.purpose,
							pending: true,
						},
					})) : [] // if there are no pending bookings, return an empty array
			),

			...(turnovers?.length ? // if there are turnovers
				turnovers.filter((turnover) => turnover.venue.id === selectedRoom) // filter the turnovers based on the selected room
					.map((turnover) => ({ // map the turnovers to the format required by the calendar
						start: new Date(`${turnover.date}T${turnover.startTime}`),
						end: new Date(`${turnover.date}T${turnover.endTime}`),
						extendedProps: {
							turnover: true,
						},
					})) : [] // if there are no turnovers, return an empty array
			),
		];

		if (!isAdmin) {
			const filteredEvents = events.filter((event) => event.end > new Date()); // filter out events that have already ended
			setEvents(filteredEvents);
		} else {
			setEvents(events);
		}

		if (events.length) {
			setIsLoading(false);
		}
	}, [bookings, pendingBookings, turnovers, selectedRoom]);

	const handleViewChange = (e) => {
		setView(e.target.value);
		setKey((prevKey) => prevKey + 1);
	};

	const handleRoomChange = (e) => {
		if (room) {
			setRoom(e.target.value);
			setSelectedRoom(e.target.value);
		} else {
			setSelectedRoom(e.target.value);
		}
	};

	// The function is called when a date is selected on the calendar and updates the selectedDate state
	const handleDateSelect = (selectInfo) => {
		// eslint-disable-next-line
		const selectedDate = new Date(selectInfo.date.getTime() - (selectInfo.date.getTimezoneOffset() * 60000)); // Adjust the date for timezone offset
		const formattedDate = selectedDate.toISOString().substr(0, 10); // format the selected date to match the date format in the database
		setSelectedDate(formattedDate);
		if (typeof onDateSelect === 'function') {
			onDateSelect(formattedDate);
		} // Pass the selected date to the parent component
	};

	// format futureDate
	const dateString = futureDate;
	const date = new Date(dateString);

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	const calendarDate = `${year}-${month}-${day}`;

	const isMobileView = () => {
		return window.innerWidth <= 768;
	};

	// renders the events on the calendar
	const renderEventContent = (event) => {
		const isMobile = isMobileView();
		const backgroundColor = event.event.extendedProps.pending ?
			'#707070' :
			event.event.extendedProps.turnover || event.event.extendedProps.isHoliday ?
				'#A9A9A9' :
				'#E76F51';

		// used to determine if the event is a holiday and if so, render the event title or PH on mobile
		const isHoliday = event.event.extendedProps.isHoliday;
		// show timings if the event is not a holiday
		const showTimingOnCalendar = !isHoliday;

		return (
			<div
				className='fc-event-main'
				style={{
					backgroundColor: backgroundColor,
					color: '#ffffff',
					borderRadius: '5px',
					padding: '3px',
					display: 'flex',
					flexGrow: 1,
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
			>
				{
					isMobile ? (
						isHoliday ? (
							// Display 'PH' for mobile view on public holiday
							<div className="fc-content" style={{fontSize: '8px'}}>
								<div className="fc-title">PH</div>
							</div>
						) : (
							// Display start and end time for mobile view
							<div style={{fontSize: '6px'}}>
								{/* Display start and end time */}
								{event.event.start.toLocaleTimeString([], {
									hour: 'numeric',
									minute: '2-digit',
									hour12: false,
								})} -{' '}
								{event.event.end.toLocaleTimeString([], {
									hour: 'numeric',
									minute: '2-digit',
									hour12: false,
								})}
							</div>
						)
					) : (
						// Display start and end time for non-mobile view for non-holiday
						showTimingOnCalendar ? (
							<div>
								{/* Display start and end time */}
								<div style={{fontSize: '12px'}}>
									{event.event.start.toLocaleTimeString([], {
										hour: 'numeric',
										minute: '2-digit',
									})} -{' '}
									{event.event.end.toLocaleTimeString([], {
										hour: 'numeric',
										minute: '2-digit',
									})}
								</div>
							</div>
						) : (
							<div className="fc-content" style={{fontSize: '12px'}}>
								{/* Display event title  on non-mobile view for holiday*/}
								<div className="fc-title">{event.event.title}</div>
							</div>
						)
					)}
			</div>
		);
	};

	// create a legend for the calendar
	const Legend = (
		<div className='d-flex justify-content-start justify-content-sm-end mt-3'>
			<div className="d-flex me-3">
				<div className='legend confirmed'></div>
				<div>Confirmed</div>
			</div>
			<div className="d-flex me-3">
				<div className='legend pending'></div>
				<div>Pending</div>
			</div>
			<div className="d-flex me-3">
				<div className='legend turnover'></div>
				<div>Unavailable</div>
			</div>
		</div>
	);

	const calendarStyling = () => {
		const dayCellContent = (arg) => {
			return (
				<div style={{color: '#707070', fontSize: '15px'}}>
					{arg.dayNumberText}
				</div>
			);
		};

		const dayHeaderContent = (arg) => {
			return (
				<div style={{color: 'white', fontWeight: 'bold'}}>
					{arg.date.toLocaleString('default', {weekday: 'short'})}
				</div>
			);
		};


		return (
			<FullCalendar
				key={key}
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
				initialView={view}
				dayCellContent={dayCellContent}
				dayHeaderContent={dayHeaderContent}
				dayMaxEventRows={5}
				validRange={isAdmin ? null : {
					start: new Date(),
					end: calendarDate,
				}}
				dateClick={handleDateSelect}
				selectable={true}
				selectMirror={isAdmin}
				eventSources={
					[
						events,
						publicHolidays.events,
					]
				}
				eventTimeFormat={{
					hour: 'numeric',
					minute: '2-digit',
					meridiem: 'short',
				}}
				eventClick={handleEventClick}
				aspectRatio={1.5}
				contentHeight={undefined}
				height={700}
				headerToolbar={{
					start: 'title',
					center: '',
					end: 'today prev,next',
				}}
				eventContent={renderEventContent}
				businessHours={businessHours}
				nowIndicator={true}
				views={{
					week: {
						allDaySlot: false,
					},
					day: {
						allDaySlot: false,
					},
				}}
			/>
		);
	};

	return (
		<div className='container mt-5'>
			<div className='row'>
				<div className='col-md-2 mt-2'>
					<select
						id="view"
						value={view}
						onChange={handleViewChange}
						style={{
							padding: '5px',
							borderRadius: '5px',
							border: '1px solid #ccc',
							backgroundColor: '#264653',
							color: '#fff',
							fontSize: '15px',
						}}
					>
						<option value="dayGridMonth" selected>View By: Month</option>
						<option value="timeGridWeek">View By: Week</option>
						<option value="timeGridDay">View By: Day</option>
					</select>
				</div>
				<div className='col-md-3 mt-2'>
					<select
						id="room"
						className='calendar-room-view'
						value={selectedRoom}
						onChange={handleRoomChange}
						style={{
							padding: '5px',
							borderRadius: '5px',
							border: '1px solid #ccc',
							backgroundColor: '#2A9D8F',
							color: '#fff',
							fontSize: '15px',
						}}
					>
						{venues.map((room) => (
							<option key={room.id} value={room.id}>{room.name}</option>
						))}
					</select>
				</div>
				<div className='col-md-7'>
					{Legend}
				</div>
			</div>
			<div className="mt-3 mb-5 text-center">
				{isLoading ? (
					<Spinner className="mt-5" animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				) : (
					calendarStyling()
				)}
			</div>
		</div>
	);
};

BookingCalendar.propTypes = {
	bookings: PropTypes.array.isRequired,
	pendingBookings: PropTypes.array.isRequired,
	turnovers: PropTypes.array.isRequired,
	onDateSelect: PropTypes.func,
	isAdmin: PropTypes.bool.isRequired,
	handleEventClick: PropTypes.func,
	futureDate: PropTypes.string,
	room: PropTypes.string,
	setRoom: PropTypes.func,
};


export default BookingCalendar;
