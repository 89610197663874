/**
 * The AdminDashboard function is a React component that fetches and displays booking, pending booking,
 * and turnover data, and allows for booking approval and cancellation.
 * @returns The `AdminDashboard` component is being returned.
 */
import React, {useState, useEffect} from 'react';
import bookingsService from '../../services/bookings';
import pendingBookingsService from '../../services/pendingBookings';
import turnoversService from '../../services/turnovers';
import AdminDashboardJsx from './AdminDashboard.jsx';

const AdminDashboard = () => {
	const [adminName, setAdminName] = useState('');
	const [bookings, setBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [turnovers, setTurnovers] = useState([]);
	const [showApproveCancelPopup, setShowApproveCancelPopup] = useState(false);
	const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [reason, setReason] = useState('');
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;

	useEffect(() => {
		// Fetch bookings, pending bookings and turnover data from server and update state
		Promise.all([
			bookingsService.getAll(),
			pendingBookingsService.getAll(),
			turnoversService.getAll(),
		]).then(([initialBookings, initialPendingBookings, initialTurnovers]) => {
			const filteredInitialBookings = initialBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			const filteredInitialPendingBookings = initialPendingBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			setBookings(filteredInitialBookings);
			setPendingBookings(filteredInitialPendingBookings);
			setTurnovers(initialTurnovers);
		});

		// retrieve Admin Name from local storage
		setAdminName(window.localStorage.getItem('name'));
	}, []);

	// handle event click on calendar
	const handleEventClick = (eventClickInfo) => {
		const selectedBookingID = eventClickInfo.event._def.extendedProps.id;

		if (selectedBookingID) {
			// find the booking/pending booking with the selected ID
			const bookingEvent = bookings.find((booking) =>
				booking.id === selectedBookingID);
			const pendingBookingEvent = pendingBookings.find((booking) =>
				booking.id === selectedBookingID);
			const selectedEvent = bookingEvent || pendingBookingEvent;

			// to determine if the selected booking is a pending booking for the approve/deny popup
			if (pendingBookingEvent) setIsPending(true);
			setSelectedBooking(selectedEvent);
			// if the selected booking is in the past, show the booking details popup instead
			if (selectedEvent.date < new Date().toISOString().slice(0, 10)) {
				setShowBookingDetailsPopup(true);
			} else {
				setShowApproveCancelPopup(true);
			}
		}
	};

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	// handle booking approval
	const onApprove = (booking) => {
		const bookingObject = {
			name: booking.name,
			dept: booking.dept,
			venue: booking.venue.id,
			date: booking.date,
			startTime: booking.startTime,
			endTime: booking.endTime,
			contactNumber: booking.contactNumber,
			purpose: booking.purpose,
			equipmentList: booking.equipmentList,
			layout: booking.layout,
			user: booking.user,
			approvedBy: adminName,
		};
		bookingsService
			.create(bookingObject)
			.then(() => {
				pendingBookingsService.remove(booking.id)
					.then(() => {
						onClose(); // close the approve/deny popup
					});
			});
	};

	const handleReasonChange = (event) => {
		setReason(event.target.value);
	};

	// handle cancel booking with reason
	const onCancel = (booking) => {
		if (isPending) {
			// if the booking is a pending booking, update the status to cancelled
			pendingBookingsService
				.update(booking.id, {status: 'Denied', cancelledReason: reason})
				.then(() => {
					onClose(); // close the approve/deny popup
				});
		} else {
			// if the booking is a booking, update the status to cancelled
			bookingsService
				.update(booking.id, {status: 'Cancelled', cancelledReason: reason})
				.then(() => {
					onClose(); // close the approve/deny popup
				});
		}
	};

	// close the approve/deny popup
	const onClose = () => {
		// Fetch bookings, pending bookings and turnover data from server and update state
		Promise.all([
			bookingsService.getAll(),
			pendingBookingsService.getAll(),
			turnoversService.getAll(),
		]).then(([initialBookings, initialPendingBookings, initialTurnovers]) => {
			const filteredInitialBookings = initialBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			const filteredInitialPendingBookings = initialPendingBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			setBookings(filteredInitialBookings);
			setPendingBookings(filteredInitialPendingBookings);
			setTurnovers(initialTurnovers);
		});

		setShowApproveCancelPopup(false);
		setShowBookingDetailsPopup(false);
		setSelectedBooking(null);
		setIsPending(false);
		setReason('');
	};

	return (
		<AdminDashboardJsx
			bookings={bookings}
			pendingBookings={pendingBookings}
			selectedBooking={selectedBooking}
			isPending={isPending}
			turnovers={turnovers}
			reason={reason}
			brandIdentifier={brandIdentifier}
			brandPages={brandPages}
			handleReasonChange={handleReasonChange}
			logOut={logOut}
			onEventClick={handleEventClick}
			showApproveCancelPopup={showApproveCancelPopup}
			showBookingDetailsPopup={showBookingDetailsPopup}
			onApprove={onApprove}
			onCancel={onCancel}
			onClose={onClose}
		/>
	);
};

export default AdminDashboard;
