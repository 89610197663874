/* This code is defining a module that exports two functions `getAll` and `getVenueName` that make HTTP
GET requests to an API endpoint using the Axios library. The `retrieveTokens` function retrieves
authentication tokens from local storage and returns them as an object. The `getAll` function uses
the `retrieveTokens` function to get the user token, sets the authorization header in the request
configuration, and returns the response data from the API. The `getVenueName` function takes a venue
ID as a parameter, uses the `retrieveTokens` function to get the user token, sets the authorization
header in the request configuration, and returns the name of the venue with the specified ID from
the API. The `baseUrl` variable is the base URL of the API endpoint. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'venues';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const retrieveTokens = () => {
	const admin = window.localStorage.getItem('adminToken');
	const user = window.localStorage.getItem('userToken');

	const adminToken = `Bearer ${admin}`;
	const userToken = `Bearer ${user}`;
	return {adminToken, userToken};
};

const getAll = () => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.get(baseBrandUrl, config);
	return request.then((response) => response.data);
};

const getVenue = (venueID) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.get(`${baseUrl}/getVenue/${venueID}`, config);
	return request.then((response) => response.data);
};

export default {getAll, getVenue};
