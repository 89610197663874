/**
 * This is a React component that displays details of a booking and allows the user to close the popup.
 * @returns The BookingDetailsPopup component is being returned.
 */
import React from 'react';
import PropTypes from 'prop-types';

const BookingDetailsPopup = ({
	booking,
	onClose,
}) => {
	// Parse the date string and format it in dd-mm-yyyy format
	const date = new Date(booking.date);
	const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: 'long', year: 'numeric'});

	return (
		<div className="booking-cancel-popup">
			<div className="approve-cancel-popup-content px-5 pb-5">
				<div style={{textAlign: 'right'}}>
					<button
						className="btn btn-link no-hover close-btn"
						onClick={onClose}
					>
						Close
					</button>
				</div>
				<div className="text-center">
					<h4>
						{formattedDate}
					</h4>
					<h2 className="my-4 text-center">
						<strong>{booking.purpose}</strong>
					</h2>
					<h4>
						<strong>Start: </strong>
					</h4>
					<h4>
						{booking.startTime}
					</h4>
					<h4>
						<strong>End: </strong>
					</h4>
					<h4>
						{booking.endTime}
					</h4>
				</div>
				<div className="text-align-left my-4">
					<label>
						<strong>Requestor: </strong> {booking.name}
					</label> <br/>
					<label>
						<strong>Department:</strong> {booking.dept}
					</label> <br/>
					<label>
						<strong>Contact:</strong> {booking.user.contactNo}
					</label> <br/>
					<label>
						<strong>Email:</strong> <p style={{overflowX: 'auto', maxWidth: '300px'}}>{booking.user.email}</p>
					</label> <br/>
					{booking.otp ? (
						<label>
							<strong>OTP:</strong> {booking.otp}
						</label>
					) : (
						null
					)}
				</div>
			</div>
		</div>
	);
};

BookingDetailsPopup.propTypes = {
	booking: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};


export default BookingDetailsPopup;
