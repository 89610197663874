/**
 * It renders a popup that asks the user to confirm the cancellation of a booking
 * @returns A popup that allows the user to cancel a booking.
 */
import React from 'react';
import PropTypes from 'prop-types';

const BookingCancelPopup = ({booking, onConfirm, onCancel}) => {
	const handleCancel = () => {
		onConfirm(booking);
		onCancel();
	};

	// Parse the date string and format it in dd-mm-yyyy format
	const date = new Date(booking.date);
	const formattedDate = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});

	return (
		<div className="booking-cancel-popup">
			<div className="booking-cancel-popup-content">
				<h2 className='mb-3 text-center'>Cancel Booking?</h2>
				<div className="text-center">
					<p>
						<strong>Venue:</strong> {booking.venue.name}
					</p>
					<p>
						<strong>Date:</strong> {formattedDate}
					</p>
					<p>
						<strong>Start Time:</strong> {booking.startTime}
					</p>
					<p>
						<strong>End Time:</strong> {booking.endTime}
					</p>
					<p>
						<strong>Purpose:</strong> {booking.purpose}
					</p>
				</div>
				<div className='text-center'>
					<button className="btn dark-btn popup-dark-btn btn-sm mb-2" onClick={onCancel}>
						<span className='btn-text'>No, Keep Booking</span>
					</button>
					<button className="btn light-btn popup-light-btn btn-sm mb-2" type='button' onClick={handleCancel}>
						<span className='btn-text'>Yes, Cancel Booking</span>
					</button>
				</div>
			</div>
		</div>
	);
};

BookingCancelPopup.propTypes = {
	booking: PropTypes.shape({
		date: PropTypes.string.isRequired,
		venue: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}).isRequired,
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		purpose: PropTypes.string.isRequired,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};


export default BookingCancelPopup;
