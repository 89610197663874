/**
 * This is a React component that displays the details of a booking and allows an admin user to edit
 * the purpose of the booking.
 * @returns This is a React functional component called `DetailsPopup`. It returns a JSX element that
 * displays the details of a booking, including the purpose, equipment request, and layout request. If
 * the user is an admin, they can edit the purpose of the booking by clicking on the edit button and
 * making changes to the input field. The component also receives props such as `booking`, `layout`,
 * and `on
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import pendingBookingService from '../services/pendingBookings';
import bookingService from '../services/bookings';
import {FaPencilAlt, FaCheck, FaTimes} from 'react-icons/fa';


const DetailsPopup = ( {booking, layout, onClose} ) => {
	const [purpose, setPurpose] = useState(booking.purpose);
	const [editable, setEditable] = useState(false);

	// Update purpose of booking in database
	const handlePurposeChange = (event) => {
		pendingBookingService
			.updatePurpose(booking.id, {'purpose': purpose})
			.then(() => {
				setEditable(false);
			})
			.catch((error) => {
				console.log(error);
			});

		// try bookingService for approved bookings
		bookingService
			.updatePurpose(booking.id, {'purpose': purpose})
			.then(() => {
				setEditable(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleEditClick = () => {
		setEditable(true);
	};

	const handleCancelClick = () => {
		setEditable(false);
		setPurpose(booking.purpose);
	};

	// Check if user is admin to show edit purpose button
	const isAdmin = () => {
		return localStorage.getItem('admin') === 'admin';
	};

	return (
		<div className="booking-popup">
			<div className="booking-popup-content" style={{maxWidth: '350px'}}>
				<h2 className="mb-3 text-center">Booking Details</h2>
				<div className="text-center">
					<p>
						<strong>Purpose: </strong>
						{isAdmin() ? (
							<>
								{editable ? (
									<>
										<input
											type="text"
											className="form-control"
											value={purpose}
											onChange={
												(event) => setPurpose(event.target.value)
											}
										/>
										<button className="btn no-hover p-2 m-1" onClick={handlePurposeChange}>
											<FaCheck className='fa-check' />
										</button>
										<button className="btn no-hover p-2 m-1" onClick={handleCancelClick}>
											<FaTimes className='fa-times' />
										</button> <br/>
									</>
								) : (
									<>
										{purpose}
										<button className="btn btn-link no-hover ps-2 pt-1" onClick={handleEditClick}>
											<FaPencilAlt className='fa-pencil' />
										</button>
									</>
								)}
							</>
						) : (
							<>{purpose} </>
						)}
					</p>
					<p>
						<strong>Equipment Request:</strong>
						{booking.equipmentList ?
							Object.entries(booking.equipmentList).map(([key, value]) => {
								return (
									<span key={key}>
										<br />
										{key}: {value}
									</span>
								);
							}) :
							<span>
								<br />
								No equipment requested
							</span>
						}
					</p>
					<p>
						<strong>Layout Request:</strong> {layout ? layout : 'NIL'}
					</p>
					<p>
						<strong>Custom Layout Request:</strong> {booking.customLayout ? booking.customLayout : 'NIL'}
					</p>
					<p>
						<strong>Expected Attendees:</strong> {booking.attendees ? booking.attendees : 'NIL'}
					</p>
					<p>
						<strong>Remarks:</strong> {booking.remarks ? booking.remarks : 'NIL'}
					</p>
					<button className="btn light-btn popup-light-btn btn-sm mb-2" onClick={onClose}>
						<span className="btn-text">Close</span>
					</button>
				</div>
			</div>
		</div>
	);
};

DetailsPopup.propTypes = {
	booking: PropTypes.shape({
		id: PropTypes.string.isRequired,
		purpose: PropTypes.string.isRequired,
		equipmentList: PropTypes.object,
		remarks: PropTypes.string,
		customLayout: PropTypes.string,
		attendees: PropTypes.string,
	}).isRequired,
	layout: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

export default DetailsPopup;
