/**
 * This is a React component that displays a popup asking the user to confirm the deletion of a user,
 * and provides information about the user to be deleted.
 * @returns A React functional component that renders a popup to confirm the deletion of a user. It
 * takes in the user object, onConfirm and onCancel functions as props and returns a div containing the
 * popup content with the user's details and two buttons to confirm or cancel the deletion.
 */
import React from 'react';
import PropTypes from 'prop-types';

const UserDeletePopup = ({user, onConfirm, onCancel}) => {
	const handleDelete = () => {
		onConfirm();
		onCancel();
	};

	return (
		<div className="booking-cancel-popup">
			<div className="user-delete-popup-content">
				<h2 className='mb-3 text-center'>Delete User?</h2>
				<div className="text-center">
					<p>
						<strong>Name:</strong> {user.name}
					</p>
					<p>
						<strong>Designation:</strong> {user.designation}
					</p>
					<p>
						<strong>Department:</strong> {user.dept}
					</p>
					<p>
						<strong>Email:</strong> {user.email}
					</p>
					<p>
						<strong>Contact Number:</strong> {user.contactNo}
					</p>
				</div>
				<div className='text-center'>
					<button className="btn dark-btn popup-dark-btn btn-sm mb-2" onClick={onCancel}>
						<span className='btn-text'>No, Keep User</span>
					</button>
					<button className="btn light-btn popup-light-btn btn-sm mb-2" onClick={handleDelete}>
						<span className='btn-text'>Yes, Delete User</span>
					</button>
				</div>
			</div>
		</div>
	);
};

UserDeletePopup.propTypes = {
	user: PropTypes.shape({
		name: PropTypes.string.isRequired,
		designation: PropTypes.string.isRequired,
		dept: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		contactNo: PropTypes.number.isRequired,
	}).isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default UserDeletePopup;
