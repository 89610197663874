/**
 * It takes in the username and password from the user, and sends it to the
 * backend to check if the user is an admin. If the user is an admin, it will
 * redirect the user to the admin dashboard.
 * @returns A form with a logo, a title, a login button, and a notification.
 */
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import loginService from '../../services/AdminLogin';
import Notification from '../Notification';
import logo from '../../assets/hospital_logo.svg';

const AdminLoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [msg, setMsg] = useState('');
	const [error, setError] = useState(false);
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;

	const navigate = useNavigate();

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setMsg('Loading...');

		const user = {username, password};
		loginService
			.login(user)
			.then((response) => {
				window.localStorage.setItem('adminToken', response.adminToken);
				window.localStorage.setItem('userToken', response.userToken);
				window.localStorage.setItem('admin', 'admin');
				window.localStorage.setItem('name', response.name);
				window.localStorage.setItem('dept', response.dept);
				window.localStorage.setItem('userId', response.userId);
				window.localStorage.setItem('contactNo', response.contactNo);
				navigate(`/${brandIdentifier}${brandPages.adminDashboard}`);
			})
			.catch(() => {
				setMsg('Wrong credentials');
				setError(true);
			})
			.finally(() => {
				setTimeout(() => {
					setMsg('');
					setError(false);
				}, 5000);
			});
	};

	return (
		<div>
			<div>
				<div className='d-flex justify-content-end my-4 me-3'>
				</div>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex flex-grow-1 justify-content-center'>
						<div id="logo">
							<img
								src={logo}
								alt="Hospital Logo"
								className='my-4 px-3 img-fluid d-block'
								width={400} height={70}
							/>
						</div>
					</div>
				</div>
				<div>
					<h1
						className='text-center mt-4'
					>
						Conference and Meeting Rooms Booking System (Administrator)
					</h1>
				</div>
			</div>
			<form onSubmit={handleFormSubmit}>
				<br/>
				<div className='container text-center'>
					<div className="mx-auto" style={{maxWidth: '400px'}}>
						<input
							id="username"
							type="text"
							className="form-control text-center"
							value={username}
							name="username"
							placeholder="Login ID"
							onChange={(e) => setUsername(e.target.value)}
							required
							style={{height: '50px'}}
						/>
					</div>
					<br />
					<div className="mx-auto" style={{maxWidth: '400px'}}>
						<input
							id="password"
							type="password"
							className='form-control text-center my-2'
							value={password}
							name="password"
							placeholder="Password"
							onChange={(e) => setPassword(e.target.value)}
							required
							style={{height: '50px'}}
						/>
					</div>
					<br />
					<div className='container text-center'>
						<button
							type="submit"
							id="adminLogin"
							className="btn dark-btn btn-lg btn-block mt-2"
						>
							<span className="btn-text">Login</span>
						</button>
					</div>
				</div>
				<div className='mt-4'>
					{msg && <Notification message={msg} isError={error} />}
				</div>
			</form>
		</div>
	);
};

export default AdminLoginPage;
