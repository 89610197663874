/**
 * The SelectFacility function is a React component that displays a list of venues and allows the user
 * to select one to proceed to a booking form.
 * @returns The SelectFacility component is being returned, which renders a page with a logo, a list of
 * facilities, and buttons to navigate back or log out. The useEffect hook is used to fetch data from
 * the venuesService and set the state of venues. The toBookingForm function is used to navigate to the
 * booking form page with the selected venue as a parameter.
 */
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/hospital_logo.svg';
import venuesService from '../../services/venues';


const SelectFacility = () => {
	const [venues, setVenues] = useState([]);
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;
	const navigate = useNavigate();

	useEffect(() => {
		venuesService.getAll().then((data) => {
			setVenues(data);
		});
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	const toBookingForm = (venue) => {
		navigate(`/${brandIdentifier}${brandPages.userForm}`, {state: {venue}});
	};

	return (
		<div>
			<div className='d-flex justify-content-end my-3 me-3'>
				<div className="me-2">
					<button className='btn btn-link no-hover' onClick={() => window.history.back()} style={{textDecoration: 'none', color: '#2a9d8f'}}> Back </button>
				</div>
				<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
			</div>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 px-3 img-fluid d-block mx-auto' width={400} height={70}/>
					</div>
				</div>
			</div>
			<div>
				<h1 className='text-center mt-5 mb-3'>Select Facility</h1>
			</div>
			<div className='d-flex justify-content-center align-items-center flex-wrap'>
				{venues.map((venue, index) => (
					<button
						key={index}
						value={venue.id}
						className='btn facility-btn m-3'
						onClick={() => toBookingForm(venue)}
					>
						{venue.name}
					</button>
				))}
			</div>
		</div>
	);
};

export default SelectFacility;
