import React, {useState, useEffect} from 'react';
// import { test, expect } from '@jest/globals';
import {
	BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import BookingForm from './components/pages/BookingForm';
import AdminLoginPage from './components/pages/AdminLogin';
import AdminDashboard from './components/pages/AdminDashboard';
import PendingBookingsPage from './components/pages/PendingBooking';
import BookingsDashboard from './components/pages/BookingsDashboard';
import BookingHistory from './components/pages/BookingHistory';
import BookingLogs from './components/pages/BookingLogs';
import SelectFacility from './components/pages/SelectFacility';
import Users from './components/pages/Users';
import CombinedView from './components/pages/CombinedView';
import AddEditUser from './components/pages/AddEditUser';
import DeviceStatus from './components/pages/DeviceStatus';
import PrivateUserRoutes from './routes/PrivateUserRoutes';
import PrivateAdminRoutes from './routes/PrivateAdminRoutes';
import brandService from './services/brands';
import '../src/assets/styles/global.scss';

// declare global variable
window.fms = {};

const App = () => {
	// eslint-disable-next-line no-unused-vars
	const [brand, setBrand] = useState(null);
	const [brandPages, setBrandPages] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [brandColors, setBrandColors] = useState(null);
	const brandIdentifier = process.env.REACT_APP_BRAND_IDENTIFIER;

	// retrieve brand data from the database
	useEffect(() => {
		const fetchData = async () => {
			const brandData = await brandService.getBrand();
			setBrand(brandData);
			setBrandPages(brandData.pages);
			setBrandColors(brandData.brandColors);

			// Assign the brandIdentifier and brandPages to the global variable
			window.fms.brandIdentifier = brandIdentifier;
			window.fms.brandPages = brandData.pages;

			// Generate CSS custom properties dynamically from brandColors data
			const root = document.documentElement;
			Object.entries(brandData.brandColors).forEach(([name, value]) => {
				root.style.setProperty(`--${name}`, value);
			});
		};

		fetchData();
	}, []);

	return (
		<>
			{brandPages && (
				<Router>
					<div>
						<Routes>
							<Route path={`/${brandIdentifier}${brandPages.home}`} element={<HomePage />} />
							<Route path={`/${brandIdentifier}${brandPages.adminLogin}`} element={<AdminLoginPage />} />
							<Route element={<PrivateUserRoutes />}>
								<Route path={`/${brandIdentifier}${brandPages.userDashboard}`} element={<BookingsDashboard />} />
								<Route path={`/${brandIdentifier}${brandPages.userHistory}`} element={<BookingHistory />} />
								<Route path={`/${brandIdentifier}${brandPages.userForm}`} element={<BookingForm />} />
								<Route path={`/${brandIdentifier}${brandPages.selectFacility}`} element={<SelectFacility />} />
							</Route>
							<Route element={<PrivateAdminRoutes />}>
								<Route path={`/${brandIdentifier}${brandPages.adminApprove}`} element={<PendingBookingsPage />} />
								<Route path={`/${brandIdentifier}${brandPages.adminDashboard}`} element={<AdminDashboard />} />
								<Route path={`/${brandIdentifier}${brandPages.adminLogs}`} element={<BookingLogs />} />
								<Route path={`/${brandIdentifier}${brandPages.adminUserList}`} element={<Users />} />
								<Route path={`/${brandIdentifier}${brandPages.adminUserForm}`} element={<AddEditUser />} />
								<Route path= '/mnh/conference-booking/admin/combinedView' element={<CombinedView />} />
							</Route>
							<Route path={`/${brandIdentifier}/conference-booking/deviceStatus`} element={<DeviceStatus />} />
						</Routes>
					</div>
				</Router>
			)}
		</>
	);
};

export default App;
