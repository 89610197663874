/* This code is defining a function called `getAvailability` that makes a GET request to an API
endpoint using the Axios library. The base URL for the API is defined using an environment variable
called `REACT_APP_API_BASE_URL`. The function returns a Promise that resolves to the response data
from the API. The code also exports the `getAvailability` function as the default export of the
module. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'availability';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const getAvailability = () => {
	const request = axios.get(baseBrandUrl);
	return request.then((response) => response.data);
};

export default {getAvailability};
