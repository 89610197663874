/**
 * This is a JavaScript function that sends a login request to an API endpoint and returns the response
 * data.
 * @param credentials - The `credentials` parameter is an object that contains the login information of
 * an admin user. It is passed as the second argument to the `axios.post()` method, which sends a POST
 * request to the `baseUrl` endpoint with the `credentials` object as the request body. The
 * `credentials` object
 * @returns The `login` function is returning a Promise that resolves to the data returned by the POST
 * request to the `baseUrl` with the provided `credentials`. The data returned is likely to be an
 * authentication token or user information.
 */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'admins';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const login = (credentials) => {
	const request = axios.post(baseBrandUrl, credentials);
	return request.then((response) => response.data);
};

export default {login};
