/**
 * If the user is authenticated, render the route, otherwise redirect to the login page
 */
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

const PrivateAdminRoutes = () => {
	const brandIdentifier = window.fms.brandIdentifier;
	const brandPages = window.fms.brandPages;

	const isAuthenticated = window.localStorage.getItem('adminToken') == null ? false : true;
	const isAdmin = window.localStorage.getItem('admin') === 'admin';
	return (
		<>
			{isAuthenticated && isAdmin ? <Outlet /> : <Navigate to={`/${brandIdentifier}${brandPages.adminLogin}`} />}
		</>

	);
};

export default PrivateAdminRoutes;
