import React, {useState, useEffect} from 'react';
import logo from '../../assets/hospital_logo.svg';
import bookingsService from '../../services/bookings';
import pendingBookingsService from '../../services/pendingBookings';
import turnoversService from '../../services/turnovers';
import availabilityService from '../../services/availability';
import NavHome from '../NavHome';
import BookingDetailsPopup from '../BookingDetailsPopup';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

const CombinedView = () => {
	const [bookings, setBookings] = useState([]);
	const [pendingBookings, setPendingBookings] = useState([]);
	const [turnovers, setTurnovers] = useState([]);
	const [view, setView] = useState('timeGridWeek'); // weekly default view
	const [key, setKey] = useState(0);
	const [events, setEvents] = useState([]);
	const viewOneVenues = ['645a1bf2fc61344772fe38ca', '6476eeeba78e1fc424d58c3e', '645a1c11fc61344772fe38d2'];
	const viewTwoVenues = ['6465e260852fb938da9b890e', '6465e246852fb938da9b890a', '6465e269852fb938da9b8912'];
	const [selectedCombinedRoom, setSelectedCombinedRoom] = useState(viewOneVenues);
	const [publicHolidays, setPublicHolidays] = useState([]);
	const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);

	const getDurationInMinutes = (startTime, endTime) => {
		const start = parseTime(startTime);
		const end = parseTime(endTime);
		const durationInMillis = end.getTime() - start.getTime();
		return Math.floor(durationInMillis / (1000 * 60)); // Duration in minutes
	};

	const parseTime = (time) => {
		const [hours, minutes] = time.split(':');
		const date = new Date();
		date.setHours(Number(hours));
		date.setMinutes(Number(minutes));
		return date;
	};

	useEffect(() => {
		// Fetch bookings, pending bookings and turnover data from server and update state
		Promise.all([
			bookingsService.getAll(),
			pendingBookingsService.getAll(),
			turnoversService.getAll(),
		]).then(([initialBookings, initialPendingBookings, initialTurnovers]) => {
			const filteredInitialBookings = initialBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			const filteredInitialPendingBookings = initialPendingBookings.filter(
				(booking) => booking.status !== 'Denied' && booking.status !== 'Cancelled',
			);
			// filter out turnovers who's duration is greater than 1 hour if CR1&2 and 30mins if CR2
			const filteredInitialTurnovers = initialTurnovers.filter((turnover) => {
				const venueId = turnover.venue.id;
				const startTime = turnover.startTime;
				const endTime = turnover.endTime;

				if (venueId === '645a1c11fc61344772fe38d2') {
					// For CR1&2 (venue ID: '645a1c11fc61344772fe38d2'), filter out turnovers longer than 1 hour
					const turnoverDuration = getDurationInMinutes(startTime, endTime);
					return turnoverDuration <= 60;
				} else if (venueId === '6476eeeba78e1fc424d58c3e') {
					// For CR2 (venue ID: '6476eeeba78e1fc424d58c3e'), filter out turnovers longer than 30 minutes
					const turnoverDuration = getDurationInMinutes(startTime, endTime);
					return turnoverDuration <= 30;
				} else if (venueId === '645a1bf2fc61344772fe38ca') {
					// For CR1 (venue ID: '645a1bf2fc61344772fe38ca'), filter out all turnovers
					return false;
				} else {
					return true; // Retain turnovers for other venues
				}
			});
			setBookings(filteredInitialBookings);
			setPendingBookings(filteredInitialPendingBookings);
			setTurnovers(filteredInitialTurnovers);
		});
	}, []);

	useEffect(() => {
		// Retrieve facility available days and hours to show on the calendar
		availabilityService
			.getAvailability()
			.then((response) => {
				const data = response;
				// create blocked holidays for the calendar
				const blockedHolidays = {
					events: data.holidaysToDisplay.map((holiday) => ({
						title: holiday.summary,
						start: new Date(`${holiday.startDate}T00:00:00`), // 12:00 am on the start date
						end: new Date(`${holiday.endDate}T00:00:00`), // 12:00 am on the end date
						extendedProps: {
							isHoliday: true,
						},
					})),
				};
				setPublicHolidays(blockedHolidays);
			});
	}, []);

	useEffect(() => {
		// Creating an array of objects that will be used to render the events on the calendar.
		const events = [
			...(bookings?.length ? // if there are bookings
				bookings.filter((booking) =>
					selectedCombinedRoom.includes(booking.venue.id)) // filter the bookings based on view1 or view2
					.map((booking) => ({ // map the bookings to the format required by the calendar
						start: new Date(`${booking.date}T${booking.startTime}`),
						end: new Date(`${booking.date}T${booking.endTime}`),
						extendedProps: {
							id: booking.id,
							name: booking.name,
							dept: booking.dept,
							contactNumber: booking.contactNumber,
							venue: booking.venue.name,
							venueId: booking.venue.id,
							purpose: booking.purpose,
							pending: false,
						},
						color: null,
					})) : [] // if there are no bookings, return an empty array
			),

			...(pendingBookings?.length ? // if there are pending bookings
				pendingBookings.filter((pendingBooking) =>
					selectedCombinedRoom.includes(pendingBooking.venue.id)) // filter the pendingbookings based on the selected room
					.map((pendingBooking) => ({ // map the pending bookings to the format required by the calendar
						start: new Date(`${pendingBooking.date}T${pendingBooking.startTime}`),
						end: new Date(`${pendingBooking.date}T${pendingBooking.endTime}`),
						extendedProps: {
							id: pendingBooking.id,
							name: pendingBooking.name,
							dept: pendingBooking.dept,
							contactNumber: pendingBooking.contactNumber,
							venue: pendingBooking.venue,
							venueId: pendingBooking.venue.id,
							purpose: pendingBooking.purpose,
							pending: true,
						},
					})) : [] // if there are no pending bookings, return an empty array
			),

			...(turnovers?.length ? // if there are turnovers
				turnovers.filter((turnover) =>
					selectedCombinedRoom.includes(turnover.venue.id)) // filter the turnovers based on the selected room
					.map((turnover) => ({ // map the turnovers to the format required by the calendar
						start: new Date(`${turnover.date}T${turnover.startTime}`),
						end: new Date(`${turnover.date}T${turnover.endTime}`),
						extendedProps: {
							turnover: true,
							venueId: turnover.venue.id,
						},
					})) : [] // if there are no turnovers, return an empty array
			),
		];

		const filteredEvents = events.filter((event) => event.end > new Date()); // filter out events that have already ended
		setEvents(filteredEvents);
	}, [bookings, pendingBookings, turnovers, selectedCombinedRoom]);

	// handle event click on calendar
	const handleEventClick = (eventClickInfo) => {
		const selectedBookingID = eventClickInfo.event._def.extendedProps.id;

		if (selectedBookingID) {
			// find the booking/pending booking with the selected ID
			const bookingEvent = bookings.find((booking) =>
				booking.id === selectedBookingID);
			const pendingBookingEvent = pendingBookings.find((booking) =>
				booking.id === selectedBookingID);
			const selectedEvent = bookingEvent || pendingBookingEvent;

			setSelectedBooking(selectedEvent);
			setShowBookingDetailsPopup(true);
		}
	};

	const handleViewChange = (e) => {
		setView(e.target.value);
		setKey((prevKey) => prevKey + 1);
	};

	const handleSelectedCombinedRoomChange = (e) => {
		if (e.target.value === 'view1') {
			setSelectedCombinedRoom(viewOneVenues);
		} else {
			setSelectedCombinedRoom(viewTwoVenues);
		}
	};

	const isMobileView = () => {
		return window.innerWidth <= 768;
	};

	// renders the events on the calendar
	const renderEventContent = (event) => {
		const bookingColorPalette = {
			'645a1bf2fc61344772fe38ca': '#D11F43', // CR1
			'6476eeeba78e1fc424d58c3e': '#03AEDA', // CR2
			'645a1c11fc61344772fe38d2': '#264653', // CR1&2
			'6465e246852fb938da9b890a': '#FFC529', // L2
			'6465e260852fb938da9b890e': '#F47736', // L4
			'6465e269852fb938da9b8912': '#0DB158', // CR5&6
		};

		const pendingColorPalette = {
			'645a1bf2fc61344772fe38ca': '#E2A6B6', // CR1
			'6476eeeba78e1fc424d58c3e': '#AFD4DD', // CR2
			'645a1c11fc61344772fe38d2': '#578191', // CR1&2
			'6465e246852fb938da9b890a': '#F7E1B0', // L2
			'6465e260852fb938da9b890e': '#F7C9B6', // L4
			'6465e269852fb938da9b8912': '#BEE1CA', // CR5&6
		};

		const isMobile = isMobileView();
		let backgroundColor;

		if (event.event.extendedProps.pending) {
			const venueId = event.event.extendedProps.venueId;
			backgroundColor = pendingColorPalette[venueId] || '#707070';
		} else if (event.event.extendedProps.isHoliday) {
			backgroundColor = '#A9A9A9';
		} else {
			const venueId = event.event.extendedProps.venueId;
			backgroundColor = bookingColorPalette[venueId] || '#E76F51';
		}

		// used to determine if the event is a holiday and if so, render the event title or PH on mobile
		const isHoliday = event.event.extendedProps.isHoliday;
		// show timings if the event is not a holiday
		const showTimingOnCalendar = !isHoliday;

		return (
			<div
				className='fc-event-main'
				style={{
					backgroundColor: backgroundColor,
					color: '#ffffff',
					borderRadius: '5px',
					padding: '3px',
					display: 'flex',
					flexGrow: 1,
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
			>
				{
					isMobile ? (
						isHoliday ? (
							// Display 'PH' for mobile view on public holiday
							<div className="fc-content" style={{fontSize: '8px'}}>
								<div className="fc-title">PH</div>
							</div>
						) : (
							// Display start and end time for mobile view
							<div style={{fontSize: '6px'}}>
								{/* Display start and end time */}
								{event.event.start.toLocaleTimeString([], {
									hour: 'numeric',
									minute: '2-digit',
									hour12: false,
								})} -{' '}
								{event.event.end.toLocaleTimeString([], {
									hour: 'numeric',
									minute: '2-digit',
									hour12: false,
								})}
							</div>
						)
					) : (
						// Display start and end time for non-mobile view for non-holiday
						showTimingOnCalendar ? (
							<div>
								{/* Display start and end time */}
								<div style={{fontSize: '7px'}}>
									{event.event.start.toLocaleTimeString([], {
										hour: 'numeric',
										minute: '2-digit',
									})} -{' '}
									{event.event.end.toLocaleTimeString([], {
										hour: 'numeric',
										minute: '2-digit',
									})}
								</div>
							</div>
						) : (
							<div className="fc-content" style={{fontSize: '12px'}}>
								{/* Display event title  on non-mobile view for holiday*/}
								<div className="fc-title">{event.event.title}</div>
							</div>
						)
					)}
			</div>
		);
	};

	// create a legend for the calendar
	const Legend = (
		<div className='text-center mt-3 mb-3'>
			<div className='container'>
				<div className='row'>
					<div className='col'>
						<div className="d-flex me-3">
							<div className='legend conferenceOne'></div>
							<div>Conference Room 1 (Confirmed)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceOnePending'></div>
							<div>Conference Room 1 (Pending)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceTwo'></div>
							<div>Conference Room 2 (Confirmed/Turnover)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceTwoPending'></div>
							<div>Conference Room 2 (Pending)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceOneTwo'></div>
							<div>Conference Room 1 & 2 (Confirmed/Turnover)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceOneTwoPending'></div>
							<div>Conference Room 1 & 2 (Pending)</div>
						</div>
					</div>
					<div className='col'>
						<div className="d-flex me-3">
							<div className='legend levelTwo'></div>
							<div>Glass Meeting Room @L2 (Confirmed)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend levelTwoPending'></div>
							<div>Glass Meeting Room @L2 (Pending)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend levelFour'></div>
							<div>Glass Meeting Room @L4 (Confirmed)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend levelFourPending'></div>
							<div>Glass Meeting Room @L4 (Pending)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceFiveSix'></div>
							<div>Conference Room 5 & 6 (Confirmed)</div>
						</div>
						<div className="d-flex me-3">
							<div className='legend conferenceFiveSixPending'></div>
							<div>Conference Room 5 & 6 (Pending)</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const calendarStyling = () => {
		const dayCellContent = (arg) => {
			return (
				<div style={{color: '#707070', fontSize: '15px'}}>
					{arg.dayNumberText}
				</div>
			);
		};

		const dayHeaderContent = (arg) => {
			return (
				<div style={{color: 'white', fontWeight: 'bold'}}>
					{arg.date.toLocaleString('default', {weekday: 'short'})}
				</div>
			);
		};


		return (
			<FullCalendar
				key={key}
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
				initialView={view}
				dayCellContent={dayCellContent}
				dayHeaderContent={dayHeaderContent}
				dayMaxEventRows={5}
				selectable={true}
				eventSources={
					[
						events,
						publicHolidays.events,
					]
				}
				eventTimeFormat={{
					hour: 'numeric',
					minute: '2-digit',
					meridiem: 'short',
				}}
				eventClick={handleEventClick}
				aspectRatio={1.5}
				contentHeight={undefined}
				height={700}
				headerToolbar={{
					start: 'title',
					center: '',
					end: 'today prev,next',
				}}
				eventContent={renderEventContent}
				nowIndicator={true}
				views={{
					week: {
						allDaySlot: false,
					},
					day: {
						allDaySlot: false,
					},
				}}
			/>
		);
	};

	// close the booking details popup
	const onClose = () => {
		setShowBookingDetailsPopup(false);
		setSelectedBooking(null);
	};

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	return (
		<div>
			<div className='d-flex justify-content-left align-items-left ms-4'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo">
						<img src={logo} alt="Hospital Logo" className='my-4 img-fluid d-block mx-auto' width={230} height={35}/>
					</div>
				</div>
				<div className="flex-grow-1"></div>
				<div className='d-flex justify-content-end align-items-center my-3 me-3'>
					<div className="me-3 ms-3">
						<NavHome />
					</div>
					<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
				</div>
			</div>
			<h1 className='text-center mb-0'>Combined View</h1>
			<br/>
			<div className='container mt-0'>
				<div className='row'>
					<div className='col-md-2 mt-2'>
						<select
							id="view"
							value={view}
							onChange={handleViewChange}
							style={{
								padding: '5px',
								borderRadius: '5px',
								border: '1px solid #ccc',
								backgroundColor: '#264653',
								color: '#fff',
								fontSize: '15px',
							}}
						>
							<option value="dayGridMonth" selected>View By: Month</option>
							<option value="timeGridWeek">View By: Week</option>
							<option value="timeGridDay">View By: Day</option>
						</select>
					</div>
					<div className='col-md-3 mt-2'>
						<select
							id="room"
							className='calendar-room-view'
							onChange={handleSelectedCombinedRoomChange}
							style={{
								padding: '5px',
								borderRadius: '5px',
								border: '1px solid #ccc',
								backgroundColor: '#2A9D8F',
								color: '#fff',
								fontSize: '15px',
							}}
						>
							<option key={'view1'} value={'view1'}>Combined View CR1 & CR2</option>
							<option key={'view2'} value={'view2'}>Combined View L2, L4, CR5&6</option>
						</select>
					</div>
				</div>
				<div className="mt-3 mb-4">
					{calendarStyling()}
					{showBookingDetailsPopup && (
						<BookingDetailsPopup
							booking={selectedBooking}
							onClose={onClose}
						/>
					)}
				</div>
			</div>
			<div>
				{Legend}
			</div>
		</div>
	);
};

export default CombinedView;
