/* This is a module that exports functions for making HTTP requests to a backend API for managing
pending bookings. It uses the Axios library to make the requests and includes functions for
retrieving authentication tokens, getting all pending bookings, creating a new booking, updating an
existing booking, and updating the purpose of a booking. The base URL for the API is retrieved from
an environment variable. */
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'pendingbookings';
const baseBrandUrl = baseUrl + '/' + process.env.REACT_APP_BRAND_IDENTIFIER;

const retrieveTokens = () => {
	const admin = window.localStorage.getItem('adminToken');
	const user = window.localStorage.getItem('userToken');

	const adminToken = `Bearer ${admin}`;
	const userToken = `Bearer ${user}`;
	return {adminToken, userToken};
};

const getAll = (getAll) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};

	const getAllRequest = getAll || 'false';
	const request = axios.get(baseBrandUrl + `/${getAllRequest}`, config);
	return request.then((response) => response.data);
};

const create = (bookingObject) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.post(baseBrandUrl, bookingObject, config);
	return request.then((response) => response.data);
};

const update = (id, bookingsObject) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};

	const request = axios.patch(`${baseBrandUrl}/${id}/`, bookingsObject, config);
	return request.then((response) => response.data);
};

const remove = (id) => {
	const {userToken} = retrieveTokens();
	const config = {
		headers: {Authorization: userToken},
	};
	const request = axios.delete(`${baseBrandUrl}/${id}`, config);
	return request.then((response) => response.data);
};

const updatePurpose = (id, purpose) => {
	const {adminToken} = retrieveTokens();
	const config = {
		headers: {Authorization: adminToken},
	};
	const request = axios.patch(`${baseUrl}/${id}`, purpose, config);
	return request.then((response) => response.data);
};

const updateTimings = (id, timings) => {
	const {adminToken} = retrieveTokens();
	const config = {
		headers: {Authorization: adminToken},
	};

	const request = axios.patch(`${baseBrandUrl}/${id}/time`, timings, config);
	return request.then((response) => response.data);
};

export default {getAll, create, update, remove, updatePurpose, updateTimings};
