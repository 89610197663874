/**
 * It renders a table of bookings, and a calendar of bookings
 * @returns A table with the bookings data.
 */
import React from 'react';
import PropTypes from 'prop-types';
import BookingCalendar from '../BookingCalendar';
import {Link} from 'react-router-dom';
import logo from '../../assets/hospital_logo.svg';
import ApproveCancelPopup from '../ApproveCancelPopup';
import BookingDetailsPopup from '../BookingDetailsPopup.js';


const AdminDashboardJsx = ({
	bookings,
	pendingBookings,
	selectedBooking,
	turnovers,
	reason,
	brandIdentifier,
	brandPages,
	logOut,
	onEventClick,
	showApproveCancelPopup,
	showBookingDetailsPopup,
	handleReasonChange,
	isPending,
	onApprove,
	onCancel,
	onClose,
}) => {
	return (
		<div>
			<div className='d-flex justify-content-left align-items-left ms-4'>
				<div className='d-flex align-items-center text-center'>
					<div id="logo-booking">
						<img src={logo} alt="Hospital Logo" className='my-4 img-fluid d-block mx-auto' width={230} height={35}/>
					</div>
				</div>
				<div className="flex-grow-1"></div>
				<div className='d-flex justify-content-end align-items-center my-3 me-3'>
					<div className='me-4'>
						<Link to={`/${brandIdentifier}${brandPages.adminUserList}`}>Users List</Link>
					</div>
					<div className='me-4'>
						<Link to={`/${brandIdentifier}${brandPages.adminLogs}`}>Booking Logs</Link>
					</div>
					<div className='me-4'>
						<Link to={`/${brandIdentifier}${brandPages.adminApprove}`}>Approve/Deny</Link>
					</div>
					<div className='me-4'>
						<Link to={`/${brandIdentifier}${brandPages.userDashboard}`}>Make Booking</Link>
					</div>
					<button className='btn btn-link no-hover logOut' onClick={logOut}>Log Out</button>
				</div>
			</div>
			<h1 className="text-center mb-2">Admin Dashboard</h1>
			<div className='text-center me-4'>
				<Link to={`/mnh/conference-booking/admin/combinedView`}>Combined View</Link>
			</div>
			<br/>
			<BookingCalendar
				bookings={bookings}
				pendingBookings={pendingBookings}
				turnovers={turnovers}
				isAdmin={true}
				handleEventClick={onEventClick}
			/>
			{showApproveCancelPopup &&
				<ApproveCancelPopup
					booking={selectedBooking}
					isPending={isPending}
					reason={reason}
					handleReasonChange={handleReasonChange}
					onApprove={onApprove}
					onCancel={onCancel}
					onClose={onClose}
				/>
			}
			{showBookingDetailsPopup &&
				<BookingDetailsPopup
					booking={selectedBooking}
					onClose={onClose}
				/>
			}
		</div>
	);
};

AdminDashboardJsx.propTypes = {
	bookings: PropTypes.array,
	pendingBookings: PropTypes.array,
	selectedBooking: PropTypes.object,
	turnovers: PropTypes.array,
	reason: PropTypes.string,
	brandIdentifier: PropTypes.string,
	brandPages: PropTypes.object,
	logOut: PropTypes.func,
	onEventClick: PropTypes.func,
	showApproveCancelPopup: PropTypes.bool,
	showBookingDetailsPopup: PropTypes.bool,
	handleReasonChange: PropTypes.func,
	isPending: PropTypes.bool,
	onApprove: PropTypes.func,
	onCancel: PropTypes.func,
	onClose: PropTypes.func,
};

export default AdminDashboardJsx;
