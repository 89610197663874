/**
 * The function fetches the pending bookings data from the backend database and displays it in a table.
 * It also has the functionality to approve or deny a booking
 * @returns A function that returns a JSX element.
 */
import React, {useState, useEffect} from 'react';
import pendingBookingsService from '../../services/pendingBookings';
import bookingsService from '../../services/bookings';
import PendingBookingsJsx from './PendingBooking.jsx';

const PendingBookingsPage = () => {
	const [adminName, setAdminName] = useState(''); // eslint-disable-line no-unused-vars
	const [pendingBookings, setPendingBookings] = useState([]);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [denyPopupVisible, setDenyPopupVisible] = useState(false);
	const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
	const [layout, setLayout] = useState('');

	useEffect(() => {
		// Fetch pending bookings data from server and update state
		pendingBookingsService
			.getAll()
			.then((initialPendingBookings) => {
				// Filter out pending bookings that are passed the current date
				const currentPendingBookings = initialPendingBookings.filter(
					(booking) => {
						const dateString = `${booking.date}T${booking.endTime}:00`;
						const bookingDateTime = new Date(dateString);
						const now = new Date();
						return bookingDateTime >= now;
					});
				// sort bookings by date from earliest to latest
				const sortedPendingBookings = currentPendingBookings
					.sort((a, b) => new Date(a.date) - new Date(b.date));
				setPendingBookings(sortedPendingBookings);
			});

		// retrieve Admin Name from local storage
		setAdminName(window.localStorage.getItem('name'));
	}, []);

	const logOut = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	// Send booking details to the backend database and email the user regarding the approval
	const handleApprove = (booking) => {
		const bookingObject = {
			name: booking.name,
			dept: booking.dept,
			venue: booking.venue.id,
			date: booking.date,
			startTime: booking.startTime,
			endTime: booking.endTime,
			contactNumber: booking.contactNumber,
			purpose: booking.purpose,
			equipmentList: booking.equipmentList,
			layout: booking.layout,
			user: booking.user,
			approvedBy: adminName,

		};
		bookingsService
			.create(bookingObject)
			.then(() => {
				pendingBookingsService.remove(booking.id)
					.then(() => {
						setPendingBookings(pendingBookings.filter((b) =>
							b.id !== booking.id),
						);
					});
			});
	};

	// display the deny popup
	const handleDeny = (booking) => {
		setSelectedBooking(booking);
		setDenyPopupVisible(true);
	};

	// Send denial reason to the backend database and email the user regarding the denial
	const handleDenySubmit = (reason) => {
		pendingBookingsService
			.update(selectedBooking.id, {status: 'Denied', cancelledReason: reason})
			.then(() => {
				// update state to reflect the changes
				pendingBookingsService
					.getAll()
					.then((initialPendingBookings) => {
						// Filter out pending bookings that are passed the current date
						const currentPendingBookings = initialPendingBookings.filter(
							(booking) => {
								const dateString = `${booking.date}T${booking.endTime}:00`;
								const bookingDateTime = new Date(dateString);
								const now = new Date();
								return bookingDateTime >= now;
							});
						// sort bookings by date from earliest to latest
						const sortedPendingBookings = currentPendingBookings
							.sort((a, b) => new Date(a.date) - new Date(b.date));
						setPendingBookings(sortedPendingBookings);
					});
			});

		setSelectedBooking(null);
		setDenyPopupVisible(false);
	};

	// close the deny popup
	const handleDenyCancel = () => {
		setDenyPopupVisible(false);
		setSelectedBooking(null);
	};

	// open the details popup
	const handleDetailsPopup = (booking) => {
		setSelectedBooking(booking);
		// Check if the necessary properties exist before accessing them
		const layout = booking.venue?.layout?.[booking.layout]?.setup || 'NIL';
		setLayout(layout !== undefined ? layout : 'NIL');
		setDetailsPopupVisible(true);
	};

	// close the details popup
	const handleDetailsClose = () => {
		setSelectedBooking(null);
		setDetailsPopupVisible(false);

		// Fetch pending bookings data from server and update state to reflect any changes
		pendingBookingsService
			.getAll()
			.then((initialPendingBookings) => {
				// Filter out pending bookings that are passed the current date
				const currentPendingBookings = initialPendingBookings.filter(
					(booking) => {
						const dateString = `${booking.date}T${booking.endTime}:00`;
						const bookingDateTime = new Date(dateString);
						const now = new Date();
						return bookingDateTime >= now;
					});
				// sort bookings by date from earliest to latest
				const sortedPendingBookings = currentPendingBookings
					.sort((a, b) => new Date(a.date) - new Date(b.date));
				setPendingBookings(sortedPendingBookings);
			});
	};

	return (
		<PendingBookingsJsx
			pendingBookings={pendingBookings}
			layout={layout}
			handleApprove={handleApprove}
			handleDeny={handleDeny}
			handleDenyCancel={handleDenyCancel}
			handleDenySubmit={handleDenySubmit}
			handleDetailsPopup={handleDetailsPopup}
			handleDetailsClose={handleDetailsClose}
			denyPopupVisible={denyPopupVisible}
			detailsPopupVisible={detailsPopupVisible}
			selectedBooking={selectedBooking}
			logOut={logOut}
		/>
	);
};

export default PendingBookingsPage;
